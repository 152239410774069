<template>
  <b-modal
    id="modal-employee-settings"
    title="Employee settings"
    size="lg"
    hide-footer
    hide-header
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    centered
    @hidden="resetModal"
  >
    <div class="d-flex justify-content-between px-2 mb-3">
      <h5>{{ developerEdit.isEdit ? 'Edit ': '' }}Employee settings</h5>
      <b-link
        class="text-secondary"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col>
          <validation-provider
            name="Role"
            :rules="developerEdit.isEdit ? '' : 'required'"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Role *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="role"
                placeholder="Select Role"
                :clearable="false"
                label="name"
                :options="roles"
                @search="fetchRole($event)"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Level"
            :rules="developerEdit.isEdit ? '' : 'required'"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Level"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="level"
                placeholder="Select Level"
                label="name"
                :options="levels"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-button
        class="next mb-3"
        variant="warning"
        @click="addRoleLevel"
      >
        Add
      </b-button>
      <b-row>
        <b-col>
          <validation-provider
            name="Role-Level"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="form.roleLevel"
                placeholder="Add Role and Level first"
                multiple
                :class="{ 'is-invalid': errors.length }"
              >
                <template #selected-option="{ role, level }">
                  {{ role.name ? role.name : role.code }} - {{ level.name }}
                </template>
              </custom-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Man A Day"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Man A Day *"
              :invalid-feedback="errors[0]"
            >
              <input
                class="form-control"
                placeholder="0"
                v-model="form.manADay"
                type="text"
                @input="restrictInput($event)"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col cols="5">
          <validation-provider
            name="Start Period"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Start Period"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="form.startDate"
                @input="form.endDate = null"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="2" align="center">
          to
        </b-col>
        <b-col cols="5">
          <validation-provider
            name="End Period"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="End Period"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="form.endDate"
                :disabled="!form.startDate"
                :min="form.startDate"
                @input="calculateTotalMandays"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Total Mandays"
          >
            <b-form-input
              v-model="form.manDays"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      class="next"
      variant="warning"
      block
      @click="submit"
    >
      Submit
    </b-button>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {
    developerInfo: {
      type: Object,
      default: () => ({})
    },
    developerEdit: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    role: null,
    level: null,
    roles: [],
    levels: [],
    form: {
      id: null,
      employeeId: null,
      fullname: null,
      manADay: 1,
      startDate: null,
      endDate: null,
      manDays: 0,
      isLeader: false,
      status: null,
      developmentTeamRoleRequests: [],
      roleLevel: []
    }
  }),

  created() {
    this.fetchRole('')
    this.fetchLevel()
  },

  watch: {
    'developerEdit.isEdit'(val) {
      if (val) {
        const item = this.developerEdit.item
        this.form = {
          id: item.id,
          employeeId: item.employeeId,
          fullname: item.fullname,
          manADay: item.manADay,
          startDate: item.startDate,
          endDate: item.endDate,
          manDays: item.manDays,
          isLeader: item.isLeader,
          status: item.status,
          developmentTeamRoleRequests: item.developmentTeamRoleRequests,
          roleLevel: item.roleLevel
        }
      }
    }
  },

  methods: {
    restrictInput(event) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        this.form.manADay = this.form.manADay.slice(0, -1)
      }
    },
    async fetchRole(key) {
      const { data } = await api.developmentTeam.roles({
        Filters: key ? `name@=*${key}` : '',
        Page: 1,
        PageSize: 10
      })
      this.roles = data.data
    },
    async fetchLevel() {
      const { data } = await api.developmentTeam.levels()
      this.levels = data.data
    },
    addRoleLevel() {
      this.form.roleLevel.push({
        role: this.role,
        level: this.level,
        label: this.form.roleLevel.length
      })
      this.form.developmentTeamRoleRequests.push({
        roleId: this.role.id,
        levelId: this.level.id
      })
    },
    calculateTotalMandays() {
      const date1 = new Date(this.form.startDate)
      const date2 = new Date(this.form.endDate)

      let count = 0
      const curDate = new Date(date1.getTime())

      // eslint-disable-next-line no-unmodified-loop-condition
      while (curDate <= date2) {
        const dayOfWeek = curDate.getDay()
        if (dayOfWeek !== 0 && dayOfWeek !== 6) count++
        curDate.setDate(curDate.getDate() + 1)
      }

      this.form.manDays = count
    },
    async submit() {
      this.form.employeeId = this.developerInfo.id
      this.form.fullname = this.developerInfo.fullname
      this.form.status = this.developerInfo.developers.length > 0 ? this.developerInfo.developers[0].status : null
      this.form.projectCount = this.developerInfo.projectCount
      this.form.developerRole = this.developerInfo.developerRole
      this.form.developerLevel = this.developerInfo.developerLevel

      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }

      this.$emit('submit', this.form)
      this.$bvModal.hide('modal-employee-settings')
    },
    resetModal() {
      this.developerEdit.isEdit = false
      this.developerEdit.isEmployee = false
      this.role = this.$options.data().role
      this.level = this.$options.data().level
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-employee-settings')
    }
  }
}
</script>
