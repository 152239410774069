<template>
    <b-modal
    id="modal-sprint"
    title="Edit Sprint"
    hide-footer
  >
   <validation-observer
      ref="form"
      slim
    >

     <b-row>
        <b-col>
          <b-row>
            <b-col cols="6">
              <validation-provider
                name="Start Date"
                rules="required"
                v-slot="{ errors }"
                slim
                class="flex-fill mr-4"
              >
                <b-form-group
                  label="Start Date *"
                  label-size="sm"
                  :invalid-feedback="errors[0]"
                >
                  <b-input-group>
                    <b-form-input
                      :value="formatDate(sprintForm.sprintStart)"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="sprintForm.sprintStart"
                        button-only
                        left
                        size="sm"
                        :min="sprintForm.sprintStart"
                        :max="sprintForm.sprintEnd"
                        locale="en-US"
                        :class="{ 'is-invalid': errors.length }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                name="End Date"
                rules="required"
                v-slot="{ errors }"
                slim
                class="flex-fill mr-4"
              >
                <b-form-group
                  label="End Date *"
                  label-size="sm"
                  :invalid-feedback="errors[0]"
                >
                  <b-input-group>
                    <b-form-input
                      :value="formatDate(sprintForm.sprintEnd)"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="sprintForm.sprintEnd"
                        button-only
                        left
                        size="sm"
                        locale="en-US"
                        :min="sprintForm.sprintStart"
                        :max="sprintForm.sprintEnd"
                        :class="{ 'is-invalid': errors.length }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        </b-row>

        <b-row>
          <b-col>
              <validation-provider
                name="Holiday"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Holiday"
                  :invalid-feedback="errors[0]"
                >
            <custom-select
              v-model="sprintForm.holidays"
              label="date"
              :clearable="false"
              :options="sprintForm.holiday"
              multiple
              :reduce="({ date }) => date"/>
              </b-form-group>
              </validation-provider>
            </b-col>
        </b-row>
        <div align="left">
 <b-button
    class="next shadow-lg"
    variant="warning"
    :disabled="isHoliday"
    @click="openModal()"
    >
        Add Holiday
      </b-button>
      </div>
    <b-row>
        <b-col>
          <validation-provider
            name="Remarks"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Remarks"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter descriptions"
                v-model="sprintForm.remark"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
        </validation-observer>
   <b-row>
    <b-col>
     <b-button
      class="primary shadow"
      variant="warning"
      block
    @click="submit"
    >
        Submit
    </b-button>
   </b-col>
  </b-row>
      <modal-holiday
      :add-holiday-form="addHolidayForm"
      @close="this.$options.data().addHolidayForm"
      :devInfoForm="devInfoForm"
      @set="addHoliday"
    />
  </b-modal>
  </template>

<script>
import moment from 'moment'
import ModalHoliday from './ModalHoliday.vue'

export default {
  components: {
    ModalHoliday
  },
  props: {
    sprintForm: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isEnable: false,
    developmentPeriodStart: '',
    developmentPeriodEnd: '',
    descriptions: null,
    holidayList: [],
    holiday: [],
    isHoliday: false,
    devInfoForm: {},
    holidayForm: [],
    addHolidayForm: {
      descriptions: null,
      date: null
    }
  }),

  methods: {
    reloadPage() {
      window.location.reload()
    },
    addHoliday(item) {
      this.sprintForm.holidays = []
      this.sprintForm.holidays.push(item.date)
      const objHoliday = {
        date: item.date,
        description: item.descriptions
      }
      this.sprintForm.holiday = []
      this.sprintForm.holiday.push(objHoliday)
    },
    openModal() {
      this.devInfoForm.startDate = moment(this.sprintForm.sprintStart).format('YYYY-MM-DD')
      this.devInfoForm.endDate = moment(this.sprintForm.sprintEnd).format('YYYY-MM-DD')
      this.$bvModal.show('modal-holiday')
    },
    formatDate(date) {
      var value = date === '' ? 'YYYY-MM-DD' : moment(date).format('YYYY-MM-DD')
      return value
    },
    async submit() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        let sprintStart = new Date(this.sprintForm.sprintStart)
        const start = moment(this.sprintForm.sprintStart)
        const end = moment(this.sprintForm.sprintEnd)
        let dayCount = end.diff(start, 'days') + 1
        const totalDay = dayCount
        let dayWeekend = 0
        while (dayCount > 0) {
          if (sprintStart.getDay() === 0 || sprintStart.getDay() === 6) {
            dayWeekend++
          }
          sprintStart = new Date(sprintStart.setDate(sprintStart.getDate() + 1))
          dayCount--
        }
        const dayLength = totalDay - dayWeekend
        const form = {
          projectId: this.sprintForm.projectId,
          sprintStart: (start).format('YYYY-MM-DD'),
          sprintEnd: (end).format('YYYY-MM-DD'),
          remarks: this.sprintForm.remark,
          dayLength: dayLength,
          holidayLength: this.sprintForm.holiday === [] ? 0 : this.sprintForm.holiday.length,
          holidays: this.sprintForm.holiday === [] ? [] : this.sprintForm.holiday
        }
        this.$emit('submit', this.sprintForm.id, form)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    }
  }
}
</script>
