<template>
    <b-modal
    id="modal-extend-days"
    title="Extend Days"
    hide-footer
  >
   <validation-observer
      ref="form"
      slim
    >

    <b-row>
        <b-col>
          <validation-provider
            name="Descriptions"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Descriptions *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter descriptions"
                v-model="descriptions"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
     <b-row>
                  <b-col>
          <validation-provider
            name="Quantity"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Quantity *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                v-model="quantity"
                type="number"
                trim
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
        <label><br/><br/>Day(s)</label></b-col>
      </b-row>
   </validation-observer>
   <b-row>
    <b-col>
     <b-button
      class="primary shadow"
      variant="warning"
      block
      :disabled="isEnable"
    @click="submit"
    >
        Submit
    </b-button>
   </b-col>
  </b-row>
  </b-modal>
  </template>

<script>
import moment from 'moment'

export default {
  props: {
    projectId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isEnable: false,
    descriptions: null,
    quantity: 0
  }),

  methods: {
    reloadPage() {
      window.location.reload()
    },
    formatDate(date) {
      var value = date === '' ? 'YYYY-MM-DD' : moment(date).format('YYYY-MM-DD')
      return value
    },
    async submit() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        const form = {
          projectId: this.projectId,
          descriptions: this.descriptions,
          days: this.quantity
        }
        this.$emit('submit', form)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    }
  }
}
</script>
