<template>
  <div class="card-project">
    <validation-observer
      ref="form"
      slim
    >
      <div class="d-flex justify-content-between">
        <h5 class="mb-4 font-weight-bold">
          Payment Term
        </h5>
        <b-button
          class="next shadow-lg"
          variant="warning"
          :disabled="isBusy"
          @click="openModal(null, false)"
        >
          Add Term
        </b-button>
      </div>
      <h6 class="font-weight-bold">
        Project Amount {{ formatMoney(projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </h6>
      <h6 class="font-weight-bold">
        Total Remaining {{ formatMoney(totalRemaining, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </h6>
      <div class="d-flex align-items-end" />
      <b-row>
        <b-col cols="2">
        <validation-provider
            name="Payment due date"
            rules="required"
            v-slot="{ errors }"
          >
          <b-form-group
            label="Payment due date"
            :invalid-feedback="errors[0]"
          >
            <input
              class="form-control"
              placeholder="0"
              v-model="paymentDueDate"
              type="text"
              @input="restrictInput($event)"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="d-flex align-items-end">
          <validation-provider
            name="Payment Due Unit"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group :invalid-feedback="errors[0]">
              <custom-select
                v-model="paymentDueUnit"
                label="text"
                :clearable="false"
                :options="dateTypeOptions"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <b-alert
      :show="totalAmount > projectAmount"
      variant="danger"
    >
      The total amount in the table exceeds the project amount!
    </b-alert>
    <b-alert
      :show="totalPercentage > 100"
      variant="danger"
    >
      The total percentage in the table exceeds 100%!
    </b-alert>
    <b-alert
      :show="isLow"
      variant="danger"
    >
      The total percentage in the table is less than 100%!
    </b-alert>
    <b-table
      class="mt-2"
      striped
      hover
      :items="dataTerm"
      :fields="fields"
    >
      <template #cell(invoiceDate)="{ item }">
        {{ item.invoiceDate ? moment(item.invoiceDate).format('DD MMM YYYY') : '' }}
      </template>
      <template #cell(percentage)="{ item }">
        {{ item.percentage }}%
      </template>
      <template #cell(amount)="{ item }">
        {{ formatMoney(item.amount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(invoiceNote)="{ item }">
        <b-link
          v-if="item.invoiceNote"
          class="text-secondary mx-2"
          @click="openNote(item.invoiceNote)"
        >
          <fa-icon icon="clipboard-list" />
        </b-link>
      </template>
      <template #cell(reminderDate)="{ item }">
        {{ item.reminderDate? moment(item.reminderDate).format('DD MMM YYYY') : '' }}
      </template>
      <template #cell(invoices)="{ item }">
        {{ item.invoiceId }}
      </template>
      <template #cell(action)="{ item, index }">
        <div class="data">
          <b-link
            class="text-secondary mx-2"
            @click="openModal(item, true)"
          >
            <fa-icon icon="edit" />
          </b-link>
          <b-link
            class="text-secondary mx-2"
            @click="removeTerm(index)"
          >
            <fa-icon icon="trash-alt" />
          </b-link>
        </div>
      </template>
    </b-table>
    <div>
      <span class="font-weight-bold">Total persentase = 100% tidak boleh kurang atau lebih &amp; total amount > project amount</span>
    </div>
    <div class="button-project d-flex justify-content-end mt-3 mb-2">
      <b-button
        v-if="!isUpdate"
        class="cancel"
        type="submit"
        variant="secondary"
        @click="cancel"
      >
        Cancel
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
          v-if="!isUpdate"
          class="reset ml-3"
          variant="outline-warning"
          @click="prevStep"
        >
          Prev
        </b-button>
      <b-button
        v-if="!isUpdate"
        class="next ml-3"
        type="submit"
        variant="warning"
        :disabled="isBusy"
        @click="savePaymentTerm()"
      >
        Next
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
        v-if="isUpdate"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="savePaymentTerm()"
      >
        Update
      </b-button>
    </div>
    <modal-term
      :tax-list="taxList"
      :add-term-form="addTermForm"
      :isEdit="isEdit"
      :total-remaining="totalRemaining"
      @close="this.$options.data().addTermForm"
      @set="addTerm"
    />
    <modal-additional-note :item="note" />
    <modal-confirmation-delete @click="deleteTerm" />
  </div>
</template>

<script>
import api from '@/api'
import ModalTerm from './ModalTerm.vue'
import ModalAdditionalNote from './ModalAdditionalNote.vue'
import ModalConfirmationDelete from './ModalConfirmationDelete.vue'
import { formatMoney } from 'accounting-js'

export default {
  components: {
    ModalTerm,
    ModalAdditionalNote,
    ModalConfirmationDelete
  },

  data: () => ({
    isBusy: false,
    projectAmount: localStorage.getItem('amount') ?? 0,
    paymentDueDate: null,
    paymentDueUnit: 'Day(s)',
    dateTypeOptions: [
      'Day(s)',
      'Month(s)',
      'Year(s)'
    ],
    fields: [
      { key: 'title', label: 'Term Title', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoiceDate', label: 'Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'percentage', label: 'Percent', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'amount', label: 'amount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remarks', label: 'Remarks', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoiceNote', label: 'Additional Note', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoices', label: 'Invoice', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'reminderDate', label: 'Reminder Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    addTermForm: {
      title: null,
      termNo: null,
      invoiceDate: null,
      reminderDate: null,
      percentage: 0,
      amount: 0,
      taxes: [],
      remarks: null,
      invoiceNote: null,
      status: null,
      invoiceId: null
    },
    termForm: [
      {
        title: 'Down Payment',
        termNo: 1,
        invoiceDate: null,
        reminderDate: null,
        percentage: 0,
        amount: 0,
        taxes: [],
        remarks: null,
        invoiceNote: null,
        status: null,
        invoiceId: null
      },
      {
        title: 'UAT Sign-off',
        termNo: 2,
        invoiceDate: null,
        reminderDate: null,
        percentage: 0,
        amount: 0,
        taxes: [],
        remarks: null,
        invoiceNote: null,
        status: null,
        invoiceId: null
      },
      {
        title: 'Live Sign-off',
        termNo: 3,
        invoiceDate: null,
        reminderDate: null,
        percentage: 0,
        amount: 0,
        taxes: [],
        remarks: null,
        invoiceNote: null,
        status: null,
        invoiceId: null
      }
    ],
    taxList: [],
    isEdit: false,
    note: null,
    isUpdate: null,
    id: null,
    isLow: false
  }),

  computed: {
    totalAmount() {
      let total = 0
      this.termForm.filter(d => d.status !== 'delete').map(v => {
        total += v.amount
      })
      return total
    },
    totalPercentage() {
      let total = 0
      this.termForm.filter(d => d.status !== 'delete').map(v => {
        total += parseInt(v.percentage)
      })
      return total
    },
    totalRemaining() {
      return this.projectAmount - this.totalAmount
    },
    dataTerm() {
      return this.termForm.filter(v => v.status !== 'delete')
    }
  },

  created() {
    this.fetchGetPaymentTerm()
  },

  methods: {
    formatMoney,
    restrictInput(event) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        this.paymentDueDate = this.paymentDueDate.slice(0, -1)
      }
    },
    prevStep() {
      this.$emit('next', 2)
      localStorage.setItem('step', 2)
    },
    setAmount() {
      this.termForm.map(v => {
        v.amount = v.percentage * this.projectAmount / 100
      })
    },
    async fetchGetPaymentTerm() {
      await this.fetchTax()
      this.isUpdate = !!this.$route.query.id
      const id = this.$route.query.id ?? localStorage.getItem('projectId')
      const { data } = await api.paymentTerm.get(id)

      this.projectAmount = data.data.projectAmount
      this.paymentDueDate = data.data.overdueLength
      this.paymentDueUnit = data.data.overdueUnit ? data.data.overdueUnit : 'Day(s)'

      if (data.data.paymentTerms.length) {
        this.termForm = data.data.paymentTerms
        this.termForm.reverse()
      } else {
        this.termForm.map(v => {
          v.amount = v.percentage * this.projectAmount / 100
        })
      }

      this.termForm.map(v => {
        delete v.projectId

        v.status = null
        if (v.taxes.length) {
          const taxes = v.taxes.map(({ taxId }) => taxId)
          v.taxes = this.taxList
            .filter(({ id }) => taxes.includes(id))
            .map(({ name }) => name)
        }
      })
    },
    openModal(item, isEdit) {
      this.isEdit = isEdit
      this.addTermForm = item || this.$options.data().addTermForm
      if (!item) {
        this.addTermForm.termNo = this.termForm.length + 1
        this.addTermForm.percentage = this.totalPercentage > 0 && this.totalPercentage <= 100
          ? 100 - this.totalPercentage : 0
      }
      this.$bvModal.show('modal-term')
    },
    openNote(item) {
      this.note = item
      this.$bvModal.show('modal-additional-note')
    },
    addTerm(item) {
      if (!this.isEdit) {
        this.termForm.push(item)
      } else {
        this.termForm.forEach(term => {
          if (term.id === item.id) {
            term = item
          }
        })
      }
    },
    removeTerm(index) {
      this.id = index
      this.$bvModal.show('modal-confirmation-delete')
    },
    deleteTerm() {
      const value = this.termForm.filter((v, i) => i === this.id)[0]
      value.status = 'delete'
      this.termForm.splice(this.id, 1, value)
    },
    nextStep() {
      this.$emit('next', 4)
      localStorage.setItem('step', 4)
    },
    async fetchTax() {
      const { data } = await api.paymentTerm.tax()
      this.taxList = data.data
    },
    async savePaymentTerm() {
      try {
        this.isLow = false
        this.isBusy = true
        const clientId = localStorage.getItem('clientId') || 0

        if (clientId !== '0' || this.projectAmount > 0) {
          const valid = await this.$refs.form.validate()
          if (!valid) {
            this.isBusy = false
            return false
          }
        }

        if (this.totalAmount > this.projectAmount || this.totalAmount < this.projectAmount ||
        this.totalPercentage > 100 || this.totalPercentage < 100) {
          if (this.totalAmount < this.projectAmount) {
            this.isLow = true
          } else {
            this.isLow = false
          }
          this.isBusy = false
          return false
        }

        const form = {
          projectId: this.$route.query.id ?? localStorage.getItem('projectId'),
          overdueLength: this.paymentDueDate,
          overdueUnit: this.paymentDueUnit,
          paymentTerms: this.$route.query.id ? this.termForm : this.dataTerm
        }

        form.paymentTerms.map(v => {
          delete v.invoiceId
          if (v.taxes.length) {
            v.taxes = this.taxList
              .filter(({ name }) => v.taxes.includes(name))
              .map(({ id }) => ({ taxId: id }))
          }
        })

        await api.paymentTerm.save(form)

        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success ${this.isUpdate ? 'update' : 'save'} payment term`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })

        if (!this.isUpdate) {
          setTimeout(() => {
            this.nextStep()
          }, 2000)
        }
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    cancel() {
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      this.$router.push('/project-list')
    }
  }
}

</script>

<style lang="scss" scoped>
.card-project {
  height: 85vh;
}

.button-project {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
