<template>
  <div class="card-project">
    <validation-observer
      ref="form"
      slim
    >
      <h5 class="mb-4 font-weight-bold">
        Project Detail
      </h5>
      <b-row class="mb-2">
        <b-col cols="6">
          <validation-provider
            name="Project Name"
            rules="required|min:2|max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Project Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter your project name"
                v-model="projectName"
                @change="generateKey"
                trim
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Project Code"
          >
            <b-form-input
              :value="projectCode"
              disabled
              trim
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <validation-provider
            name="KEY"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <b-form-group
              label="KEY *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                v-model="projectKey"
                trim
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <validation-provider
            name="Type of Cooperation"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Type of Cooperation *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="cooperation"
                placeholder="Select Cooperation"
                :clearable="false"
                :options="cooperationOption"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group
            label="Required Resource"
          >
            <custom-select
              v-model="requiredResource"
              :options="newOpt"
              label="developmentRoles"
              :closeOnSelect="false"
              multiple
              placeholder="Select Required Resource"
              :clearable="true"
              @option:deselected="deselect"
            >
              <template #option="{ developmentRoles, quantity }">
                <div class="d-flex justify-content-between">
                  <span>{{ developmentRoles }}</span>
                  <div>
                    <b-button
                      @click="decrement(developmentRoles)"
                      :disabled="quantity < 2"
                      size="sm"
                      variant="outline-secondary"
                    > - </b-button>
                    {{ quantity }}
                    <b-button
                      @click="increment(developmentRoles)"
                      size="sm"
                      variant="outline-secondary"
                    > + </b-button>
                  </div>
                </div>
              </template>
              <template #selected-option="{ developmentRoles, quantity }">
                {{ quantity }} {{ developmentRoles }}
              </template>
            </custom-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-group
            label="Technology"
          >
            <custom-select
              v-model="technology"
              label="name"
              :clearable="false"
              :options="technologyList"
              multiple
              taggable
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Status"
          >
            <custom-select
              v-model="status"
              label="name"
              :clearable="false"
              :options="statusList"
              :reduce="({ name }) => name"
              :disabled="isUpdate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <validation-provider
            name="Short Description"
            rules="max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Short Description"
              :invalid-feedback="errors[0]"
            >
              <b-form-textarea
                v-model="shortDescription"
                placeholder="Enter your short description"
                rows="4"
                max-rows="6"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Description"
            rules="max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Description"
              :invalid-feedback="errors[0]"
            >
              <b-form-textarea
                v-model="description"
                placeholder="Enter your description"
                rows="4"
                max-rows="6"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="6">
          <validation-provider
            name="Init State"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Init State"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="initState"
                :clearable="false"
                placeholder="Select Init State"
                :options="initStateOption"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div class="d-flex mt-4 w-100 mb-5">
        <div class="w-50 pr-3">
          <h5 class="mb-4 font-weight-bold">
            Client
          </h5>
          <b-row class="mb-2">
            <b-col>
              <b-form-group
                label="Client Name"
              >
                <custom-select
                  v-model="clientId"
                  label="code"
                  :options="clientList"
                  :reduce="({ id }) => id"
                  @input="registerPIC"
                  placeholder="Select Client"
                  append-to-body
                >
                  <template #list-footer>
                    <div class="text-center mx-5 my-2">
                    <b-button
                      class="next"
                      variant="warning"
                      v-b-modal.modal-add-client
                      size="sm"
                      block
                    >
                      Add New Client
                    </b-button>
                    </div>
                  </template>
                </custom-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div
          class="w-50 pl-3"
          v-if="clientId"
        >
          <div class="d-flex justify-content-between">
            <h5 class="mb-4 font-weight-bold">
              PIC Project
            </h5>
            <div class="d-flex">
              Same with PIC Client?
              <b-form-checkbox
                class="ml-2"
                v-model="isRegisteredPIC"
                @change="registerPIC"
                switch
              />
            </div>
          </div>
          <b-row class="mb-2">
            <b-col>
              <validation-provider
                name="PIC Name"
                :rules="clientId ? 'required' : ''"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="PIC Name *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter pic name"
                    v-model="picName"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <validation-provider
                name="No Handphone"
                :rules="clientId ? 'required' : ''"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="No Handphone *"
                  :invalid-feedback="errors[0]"
                >
                  <input
                    class="form-control"
                    placeholder="Enter no handphone"
                    v-model="picPhone"
                    type="text"
                    @input="restrictInput($event)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <validation-provider
                name="Email PIC"
                :rules="clientId ? 'required|email' : ''"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Email PIC *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter pic email"
                    v-model="picEmail"
                    type="email"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </div>
    </validation-observer>
    <div />
    <div class="button-project mt-5 d-flex justify-content-end">
      <b-button
        v-if="!isUpdate"
        class="cancel"
        type="submit"
        variant="secondary"
        @click="cancel"
      >
        Cancel
      </b-button>
      <b-button
        v-if="!isUpdate"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="saveProjectDetail()"
      >
        Next
      </b-button>
      <b-button
        v-if="isUpdate"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="saveProjectDetail()"
      >
        Update
      </b-button>
    </div>
    <modal-add-client @submit="submitClient"></modal-add-client>
  </div>
</template>

<script>
import api from '@/api'
import ModalAddClient from '@/components/ModalAddClient.vue'

export default {
  components: {
    ModalAddClient
  },
  data: () => ({
    isBusy: false,
    projectName: null,
    projectCode: null,
    projectKey: '',
    cooperation: null,
    requiredResource: [],
    technology: null,
    status: null,
    initState: null,
    description: null,
    shortDescription: null,
    clientId: null,
    picName: null,
    picPhone: null,
    picEmail: null,
    isRegisteredPIC: false,
    statusList: [],
    technologyList: [],
    devRoles: [],
    clientList: [],
    initStateOption: [
      'New', 'Legacy', 'Continue'
    ],
    cooperationOption: [
      'Mandays', 'Software'
    ],
    newOpt: [],
    isUpdate: false
  }),

  created() {
    this.fetchStatusList()
    this.fetchTechnologyList()
    this.fetchDevRolesList()
    this.fetchClientList()

    if (this.$route.query.id || localStorage.getItem('projectId')) this.fetchGetProjectDetail()
  },

  computed: {
  },

  methods: {
    restrictInput(event) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        this.picPhone = this.picPhone.slice(0, -1)
      }
    },
    async submitClient(form) {
      try {
        const { data } = await api.client.save(form)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save Client', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-add-client')
        }, 2000)
        this.fetchClientList()
        this.clientId = data.data.id
        this.picName = data.data.picName
        this.picPhone = data.data.picPhone
        this.picEmail = data.data.picEmail
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async fetchGetProjectDetail() {
      this.isUpdate = !!this.$route.query.id

      const id = this.$route.query.id ? this.$route.query.id : localStorage.getItem('projectId')
      const { data } = await api.projectDetail.get(id)

      this.projectName = data.data.name
      this.projectCode = data.data.code
      this.projectKey = data.data.key
      this.cooperation = data.data.typeOfCoorporation
      const resource = []
      data.data.projectResources.map(v => {
        const name = v.level !== null ? v.developmentRole.name + ' ' + v.level : v.developmentRole.name
        this.requiredResource.push({
          developmentRoles: name,
          quantity: v.qty
        })

        resource.push({
          qty: v.qty,
          roleName: v.developmentRole.name,
          levelName: v.level
        })

        const index = this.newOpt.findIndex(d => d.developmentRoles === name)
        if (index >= 0) {
          this.newOpt[index].quantity = v.qty
        }
      })
      this.technology = data.data.technology
      this.status = data.data.status
      this.initState = data.data.initState
      this.description = data.data.description
      this.shortDescription = data.data.shortDescription
      this.clientId = data.data.clientId
      localStorage.setItem('clientId', this.clientId)
      this.isRegisteredPIC = data.data.isRegisteredPIC
      this.picName = data.data.client?.picName
      this.picPhone = data.data.client?.picPhone
      this.picEmail = data.data.client?.picEmail

      localStorage.setItem('projectResources', JSON.stringify(resource))
      localStorage.setItem('projectName', data.data.name)
      localStorage.setItem('projectStatus', data.data.status)
    },
    async fetchStatusList() {
      const { data } = await api.projectDetail.status()

      this.statusList = data.data.filter(v => v.isInitStatus)
      data.data.map(v => {
        if (v.isDefaultInitStatus) {
          this.status = v.name
        }
      })
    },
    async fetchTechnologyList() {
      const { data } = await api.projectDetail.technologies()
      this.technologyList = data.data
    },
    async fetchDevRolesList() {
      const { data } = await api.projectDetail.devRoles()
      this.devRoles = data.data.developmentRoles
      this.devRoles.forEach(v => this.newOpt.push({ developmentRoles: v, quantity: 0 }))
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data
    },
    nextStep() {
      this.$emit('next', 2)
      localStorage.setItem('step', 2)
    },
    registerPIC() {
      if (this.clientId && this.isRegisteredPIC) {
        const client = this.clientList.filter(v => v.id === this.clientId)[0]
        this.picName = client.picName
        this.picPhone = client.picPhone
        this.picEmail = client.picEmail
      } else {
        this.picName = null
        this.picPhone = null
        this.picEmail = null
      }
    },
    async saveProjectDetail() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        const form = {
          client: this.clientId ? {
            id: this.clientId,
            isRegisteredPIC: this.isRegisteredPIC,
            pic: {
              name: this.picName,
              noHandphone: this.picPhone,
              email: this.picEmail
            }
          } : null,
          name: this.projectName,
          key: this.projectKey,
          initState: this.initState,
          status: this.status,
          shortDescription: this.shortDescription,
          description: this.description,
          technologies: this.technology,
          resources: this.requiredResource,
          typeOfCorporation: this.cooperation
        }

        if (this.isUpdate || localStorage.getItem('projectId')) {
          form.id = this.$route.query.id ?? localStorage.getItem('projectId')
          const { data } = await api.projectDetail.update(form)
          localStorage.setItem('projectName', data.data.name)
          localStorage.setItem('projectStatus', data.data.status)

          const resource = []
          data.data.projectResources.map(v => {
            resource.push({
              qty: v.qty,
              roleName: v.developmentRole.name,
              levelName: v.level
            })
          })
          localStorage.setItem('projectResources', JSON.stringify(resource))
        } else {
          const { data } = await api.projectDetail.save(form)
          localStorage.setItem('projectId', data.data.id)
          localStorage.setItem('projectCode', data.data.code)

          const resource = []
          data.data.projectResources.map(v => {
            resource.push({
              qty: v.qty,
              roleName: v.developmentRole.name,
              levelName: v.level
            })
          })
          localStorage.setItem('projectResources', JSON.stringify(resource))
        }
        localStorage.setItem('clientId', form.client)

        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success ${this.isUpdate ? 'update' : 'save'} project detail`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })

            if (this.isUpdate) {
              setTimeout(() => {
                window.location.reload()
                this.isBusy = false
              }, 1500)
            } else {
              setTimeout(() => {
                this.nextStep()
                this.isBusy = false
              }, 1500)
            }
          }, 500)
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    increment(label) {
      const indexExist = this.requiredResource.findIndex(v => v.developmentRoles === label)
      if (indexExist >= 0) {
        this.requiredResource.splice(indexExist, 1)
      }
      const index = this.newOpt.findIndex(v => v.developmentRoles === label)
      this.newOpt[index].quantity++
    },
    decrement(label) {
      const index = this.newOpt.findIndex(v => v.developmentRoles === label)
      this.newOpt[index].quantity--
    },
    deselect(item) {
      this.newOpt.map(v => {
        if (v.developmentRoles === item.developmentRoles) {
          v.quantity = 0
        }
      })
    },
    generateKey(value) {
      this.projectKey = ''
      const v = value.split(' ')

      if (v.length > 1) {
        v.map((d, i) => {
          if (i < 3 || parseInt(d)) {
            this.projectKey += d.charAt(0)
          }
        })
      } else {
        const k = v[0].split('')
        k.map((d, i) => {
          if (i < 5) {
            this.projectKey += d.charAt(0)
          }
        })
      }
    },
    cancel() {
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      this.$router.push('/project-list')
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  margin-top: 0;
}

.col, .col-2, .col-6 {
  padding: 0 16px;
}

.vs__dropdown-menu {
  padding-left: 0;
}

.v-application ul {
  padding-left: 0 !important;
}
</style>
