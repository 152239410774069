<template>
 <div class="card-project">
    <validation-observer
      ref="form"
      slim
    >
    <h5 class="mb-4 font-weight-bold">
        Development Info
    </h5>
      <b-row class="mb-2">
        <b-col cols="6">
          <validation-provider
            name="Development Method"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Development Info *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="developmentMethod"
                :clearable="false"
                :options="developmentInfoOption"
                placeholder="Select Development Method"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-row>
          <b-col>
          <validation-provider
            name="Quantity"
            rules="required|min_value:1"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Quantity *"
              :invalid-feedback="errors[0]"
            >
              <input
                class="form-control"
                placeholder="0"
                v-model="quantity"
                type="text"
                @input="restrictInput($event)"
                @change="getStartDate"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
        <label class="font-weight-bold mb-1"><br/><br/>Sprint</label></b-col>
        </b-row>
        </b-col>
    </b-row>
    <b-row class="mb-2">
        <b-col cols="6">
          <validation-provider
            name="Day of Sprint"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Day of Sprint *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="dayOfSprint"
                :clearable="false"
                :options="dayOfSprintOption"
                @input="getStartDate"
                placholder="Select day of sprint"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            name="Kickoff Date"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Kickoff Date"
              :invalid-feedback="errors[0]"
            >
              <date-picker placeholder="YYYY-MM-DD" v-model="kickoffDate" v-on:input="getStartDate" ></date-picker>
            </b-form-group>
          </validation-provider>
        </b-col>
    </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="6">
              <validation-provider
                name="Development Period"
                rules="required"
                v-slot="{ errors }"
                slim
                class="flex-fill mr-4"
              >
                <b-form-group
                  label="Development Periode"
                  label-size="sm"
                  :invalid-feedback="errors[0]"
                >
                  <b-input-group>
                    <b-form-input
                      :value="formatDate(developmentPeriodStart)"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="developmentPeriodStart"
                        :min="startDate"
                        button-only
                        left
                        size="sm"
                        locale="en-US"
                        @input="getEndDate()"
                        :class="{ 'is-invalid': errors.length }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                name="Development Periode"
                rules="required"
                v-slot="{ errors }"
                slim
                class="flex-fill mr-4"
              >
                <b-form-group
                  label=" "
                  label-size="sm"
                  label-class="font-weight-bold"
                  :invalid-feedback="errors[0]"
                >
                  <br />
                  <b-input-group>
                    <b-form-input
                      :value="formatDate(developmentPeriodEnd)"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="developmentPeriodEnd"
                        button-only
                        left
                        size="sm"
                        locale="en-US"
                        :class="{ 'is-invalid': errors.length }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
              <validation-provider
                name="Holiday"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Holiday"
                  :invalid-feedback="errors[0]"
                >
            <custom-select
              v-model="holiday"
              label="name"
              :clearable="false"
              :closeOnSelect="false"
              multiple/>
              </b-form-group>
              </validation-provider>
            </b-col>
        </b-row>
<div align="right">
  <b-button
    class="next shadow-lg"
    variant="warning"
    :disabled="isHoliday"
    @click="openModal()"
    >
        Add Holiday
      </b-button>
      </div>
     <div class="d-flex mt-4 w-100">
        <div class="w-50 pr-3">
          <h5 class="mb-4 font-weight-bold">
            Maintenance
          </h5>
          <b-row class="mb-2">
            <b-col>
              <validation-provider
                name="Maintenance Length"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Maintenance Length"
                  :invalid-feedback="errors[0]"
                >
                  <input
                    class="form-control"
                    placeholder="0"
                    v-model="longMaintenance"
                    type="text"
                    @input="restrictInput($event, 'maintenance')"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <br/>
            <b-col cols="3">
            <b-form-group
              label=""
            >
             <label><br/></label>
              <custom-select
                v-model="longMaintenanceUnit"
                :clearable="false"
                @input="getEndMaintenanceDate"
                :options="longMaintenanceUnitOption"
              />
            </b-form-group>
        </b-col>
          </b-row>
          </div>
        <div class="w-50 pr-3">
        <h5 class="mb-4 font-weight-bold"><br/></h5>
          <b-row>
            <b-col cols="6">
              <validation-provider
                name="Maintenance Period"
                rules="required"
                v-slot="{ errors }"
                slim
                class="flex-fill mr-4"
              >
                <b-form-group
                  label="Maintenance Period"
                  label-size="sm"
                  :invalid-feedback="errors[0]"
                >
                  <b-input-group>
                    <b-form-input
                      :value="formatDate(maintenancePeriodStart)"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="maintenancePeriodStart"
                        button-only
                        :min="maintenanceStart"
                        left
                        size="sm"
                        locale="en-US"
                        @input="getEndMaintenanceDate()"
                        :class="{ 'is-invalid': errors.length }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                name="Maintenance Periode"
                rules="required"
                v-slot="{ errors }"
                slim
                class="flex-fill mr-4"
              >
                <b-form-group
                  label=" "
                  label-size="sm"
                  label-class="font-weight-bold"
                  :invalid-feedback="errors[0]"
                >
                  <br />
                  <b-input-group>
                    <b-form-input
                      :value="formatDate(maintenancePeriodEnd)"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="maintenancePeriodEnd"
                        button-only
                        left
                        size="sm"
                        locale="en-US"
                        :class="{ 'is-invalid': errors.length }"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          </div>
        </div>
    </validation-observer>
    <div class="mt-5 d-flex justify-content-end">
      <b-button
        v-if="!isEdit"
        class="cancel"
        type="submit"
        variant="secondary"
        @click="cancel"
      >
        Cancel
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
          v-if="!isEdit"
          class="reset ml-3"
          variant="outline-warning"
          @click="prevStep"
        >
          Prev
        </b-button>
      <b-button
        v-if="!isEdit"
        class="next ml-3"
        type="submit"
        variant="warning"
        :disabled="isBusy"
        @click="saveDevInfo"
      >
        Next
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
        v-if="isEdit"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="saveDevInfo"
      >
        Update
      </b-button>
      </div>
      <br/>
<div>
  <div class="d-flex justify-content-between">
    <h5 v-if="sprintList.length > 0" class="mb-4 font-weight-bold">
          Sprint List
        </h5>
        <b-button
        v-if="sprintList.length  > 0"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="openExtendDays()"
      >
        Extend Days
      </b-button>
      </div>
      <b-table
      v-show="sprintList.length > 0"
      class="mt-2"
      striped
      empty-text="No data"
      :items="sprintList"
      :fields="fields"
      show-empty
    >
      <template #cell(action)="{ item }">
        <div class="data">
          <b-link
            class="text-secondary mx-2"
            @click="openSprint(item)"
          >
            <fa-icon icon="edit" />
          </b-link>
        </div>
      </template>

       <template #cell(sprintStart)="{ item }">
          {{ item.sprintStart ? moment(item.sprintStart).format('DD MMM YYYY') : '-' }}
        </template>

        <template #cell(sprintEnd)="{ item }">
          {{ item.sprintEnd ? moment(item.sprintEnd).format('DD MMM YYYY') : '-' }}
         </template>
    </b-table>
    </div>
    <modal-holiday
      :add-holiday-form="addHolidayForm"
      @close="this.$options.data().addHolidayForm"
      :devInfoForm="devInfoForm"
      @set="addHoliday"
    />
    <modal-extend-days
    :projectId="projectId" @submit="submitExtendDays"/>
    <modal-sprint
    :sprintForm="sprintForm" @submit="submitSprint"/>
</div>
</template>

<script>
import moment from 'moment'
import ModalHoliday from './ModalHoliday.vue'
import ModalExtendDays from './ModalExtendDays.vue'
import ModalSprint from './ModalSprint.vue'
import api from '@/api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    ModalHoliday,
    ModalExtendDays,
    ModalSprint,
    DatePicker
  },

  data: () => ({
    isBusy: false,
    isEdit: false,
    isHoliday: true,
    id: null,
    startDate: '',
    endDate: '',
    maintenanceStart: '',
    kickoffDate: '',
    quantity: 0,
    holiday: [],
    longMaintenance: '',
    developmentPeriodStart: '',
    developmentPeriodEnd: '',
    maintenancePeriodStart: '',
    maintenancePeriodEnd: '',
    developmentInfo: [],
    dayOfSprint: '',
    dayOfSprintOption: [],
    longMaintenanceUnit: null,
    sprintList: [],
    sprintDates: [],
    sprintForm: {},
    fields: [
      { key: 'sprintName', label: 'Sprint No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'startDay', label: 'Start Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'sprintStart', label: 'Start Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'endDay', label: 'End Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'sprintEnd', label: 'End Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'holidays', label: 'Holidays', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'totalWorkDay', label: 'Total Work Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'totalHoliday', label: 'Total Holiday', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'duration', label: 'Duration', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remark', label: 'Remark', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    developmentMethod: 'Scrum',
    longMaintenanceUnitOption: ['days', 'months', 'years'],
    developmentInfoOption: [
      'Scrum'
    ],
    addHolidayForm: {
      descriptions: null,
      date: null
    },
    devInfoForm: {
      description: null,
      date: null
    },
    holidayForm: [],
    isUpdate: false,
    projectId: null
  }),
  created() {
    this.fetchDayOfSprint()
    this.fetchSprintList()
    if (this.$route.query.id || localStorage.getItem('projectId')) this.fetchDevInfoDetail()
  },
  methods: {
    restrictInput(event, field) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        if (field === 'maintenance') {
          this.longMaintenance = this.longMaintenance.slice(0, -1)
        } else {
          this.quantity = this.quantity.slice(0, -1)
        }
      }
    },
    async submitSprint(id, form) {
      await api.developmentInfo.editSprint(id, form)
      this.$nextTick(() => {
        setTimeout(() => {
          this.$bvToast.toast('Success save sprint', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
        }, 500)
      })
      setTimeout(() => {
        this.$bvModal.hide('modal-sprint')
      }, 2000)
      this.fetchDevInfoDetail()
      this.fetchSprintList()
    },
    async submitExtendDays(form) {
      await api.developmentInfo.saveExtendDays(form)
      this.$nextTick(() => {
        setTimeout(() => {
          this.$bvToast.toast('Success save Extend Days', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
        }, 500)
      })
      setTimeout(() => {
        this.$bvModal.hide('modal-extend-days')
      }, 2000)
      this.fetchDevInfoDetail()
      this.fetchSprintList()
    },
    async fetchSprintList() {
      const projectId = this.$route.query.id || localStorage.getItem('projectId')
      if (projectId) {
        const data = await api.developmentInfo.getSprint(projectId)
        this.sprintList = data.data
        this.sprintList.map((v, i) => {
          if (i % 2 === 0) {
            v._rowVariant = 'warning'
          }
        })
      }
    },
    async fetchDevInfoDetail() {
      this.projectId = this.$route.query.id || localStorage.getItem('projectId')
      const data = await api.developmentInfo.get(this.projectId)
      const devData = data.data
      this.isUpdate = devData.developmentMethod !== null
      this.id = devData.id
      this.isEdit = this.$route.query.id
      this.isHoliday = false
      this.developmentMethod = devData.developmentMethod !== null ? devData.developmentMethod : this.developmentMethod
      this.kickoffDate = new Date(devData.kickoffDate)
      this.quantity = Number(devData.quantity)
      this.holiday = devData.holidays
      this.dayOfSprint = devData.daysOfSprint
      this.longMaintenance = devData.maintenanceLength
      this.developmentPeriodStart = devData.developmentStart
      this.developmentPeriodEnd = devData.developmentEnd
      this.maintenancePeriodStart = devData.maintenanceStart
      this.maintenancePeriodEnd = devData.maintenanceEnd
      this.longMaintenanceUnit = devData.developmentUnit
      this.sprintDates = devData.sprintDates
      this.getEndDate()
    },
    async saveDevInfo() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        var sprintData = {}
        let startDate = new Date(this.developmentPeriodStart)
        let sprintEndDate = ''
        let sprintStartDate = ''
        const tmpSprintDates = []
        const startDateParam = new Date(this.developmentPeriodStart)
        for (let i = 1; i <= this.quantity; i++) {
          let dayOfSprint = this.dayOfSprint !== '' ? Number(this.dayOfSprint.split(' ')[0]) : 0
          let dayOfSprintStart = dayOfSprint
          const totalDaySprint = dayOfSprint
          if (i >= 2) {
            while (dayOfSprintStart > 0) {
              sprintStartDate = new Date(startDateParam.setDate(startDateParam.getDate() + 1))
              if (sprintStartDate.getDay() !== 0 && sprintStartDate.getDay() !== 6) {
                dayOfSprintStart--
              }
            }
          } else {
            sprintStartDate = startDateParam
          }
          startDate = new Date(sprintStartDate)
          while (dayOfSprint > 1) {
            sprintEndDate = new Date(startDate.setDate(startDate.getDate() + 1))
            if (sprintEndDate.getDay() !== 0 && sprintEndDate.getDay() !== 6) {
              dayOfSprint--
            }
          }
          var holidayList = []
          var totalHoliday = 0
          this.holiday.forEach((data) => {
            if (moment(data.holidayDate).format('YYYY-MM-DD') >= moment(sprintStartDate).format('YYYY-MM-DD') && moment(data.holidayDate).format('YYYY-MM-DD') <= moment(sprintEndDate).format('YYYY-MM-DD')) {
              var holidayData = {
                date: data.holidayDate,
                description: data.description
              }
              holidayList.push(holidayData)
              totalHoliday = totalHoliday + 1
            }
          })
          if (this.sprintDates.length > 0) this.sprintDates = this.sprintDates.reverse()
          const remark = (this.sprintDates.length > 0) ? this.sprintDates.find(e => e.sprintname === String(i)).remark : null
          const id = (this.sprintDates.length > 0) ? this.sprintDates.find(e => e.sprintname === String(i)).id : null
          sprintData = {
            id: id,
            sprintName: String(i),
            sprintStart: this.formatDate(sprintStartDate),
            sprintEnd: this.formatDate(sprintEndDate),
            dayLength: totalDaySprint,
            holidayLength: totalHoliday,
            holidays: holidayList,
            remark: remark
          }
          if (tmpSprintDates.length === 0) {
            tmpSprintDates.push(sprintData)
          } else if (tmpSprintDates.length > 0) {
            var isExist = tmpSprintDates.find(e => e.sprintName !== sprintData.sprintName)
            if (isExist) tmpSprintDates.push(sprintData)
          }
          totalHoliday = 0
        }

        const form = {
          projectId: this.$route.query.id || localStorage.getItem('projectId'),
          developmentMethod: this.developmentMethod,
          quantity: Number(this.quantity),
          dayOfSprint: this.dayOfSprint.split(' ')[0],
          kickoffDate: this.formatDate(this.kickoffDate),
          developmentStart: this.formatDate(this.developmentPeriodStart),
          developmentEnd: this.formatDate(this.developmentPeriodEnd),
          maintenanceLength: this.longMaintenance,
          maintenanceStart: this.maintenancePeriodStart,
          maintenanceEnd: this.maintenancePeriodEnd,
          developmentUnit: this.longMaintenanceUnit,
          sprintDates: tmpSprintDates
        }
        if (this.sprintDates.length > 0) {
          await api.developmentInfo.edit(form)
        } else {
          await api.developmentInfo.save(form)
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success ${this.isUpdate ? 'update' : 'save'} development Info`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.fetchSprintList()
        this.fetchDevInfoDetail()
        setTimeout(() => {
          this.nextStep()
        }, 2000)
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    formatDate(date) {
      var value = date === null || date === '' ? 'YYYY-MM-DD' : moment(date).format('YYYY-MM-DD')
      return value
    },
    getStartDate() {
      this.startDate = (this.kickoffDate)
      this.developmentPeriodStart = this.kickoffDate
      if (this.quantity > 0 && this.daysOfSprint !== '' && this.developmentPeriodStart !== null) this.getEndDate()
    },
    getMaintenanceStart() {
      this.maintenanceStart = moment(this.developmentPeriodEnd).add(1, 'days').format('YYYY-MM-DD')
      this.maintenancePeriodStart = this.maintenanceStart
    },
    getEndDate() {
      const startDate = this.developmentPeriodStart === null ? '' : new Date(this.developmentPeriodStart)
      let endDate = ''
      if (startDate.getDay() === 0 && startDate.getDay() === 6) {
        this.$bvToast.toast('Development period must be weekday', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
      }
      const dayOfSprint = this.dayOfSprint !== '' ? Number(this.dayOfSprint.split(' ')[0]) : 0

      let offset = this.quantity * dayOfSprint
      while (offset > 1) {
        endDate = new Date(startDate.setDate(startDate.getDate() + 1))
        if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
          offset--
        }
      }
      this.developmentPeriodEnd = endDate
      this.fetchHoliday(this.developmentPeriodStart, this.developmentPeriodEnd)
      if (this.developmentPeriodEnd !== '') this.isHoliday = false
      this.devInfoForm.startDate = this.developmentPeriodStart
      this.devInfoForm.endDate = this.developmentPeriodEnd
      this.getMaintenanceStart()
    },
    getEndMaintenanceDate() {
      this.maintenancePeriodEnd = moment(this.maintenancePeriodStart).add(Number(this.longMaintenance), this.longMaintenanceUnit).format('YYYY-MM-DD')
    },
    openModal() {
      this.$bvModal.show('modal-holiday')
    },
    openExtendDays() {
      this.$bvModal.show('modal-extend-days')
    },
    openSprint(item) {
      item.projectId = this.projectId
      this.sprintForm = item
      this.sprintForm.sprintStart = new Date(this.sprintForm.sprintStart)
      this.sprintForm.sprintEnd = new Date(this.sprintForm.sprintEnd)
      this.$bvModal.show('modal-sprint')
    },
    addHoliday(item) {
      this.holidayForm.push(item)
      this.holiday.push()
      const objHoliday = {
        holidayDate: item.date,
        description: item.descriptions,
        name: item.descriptions + ', ' + item.date
      }
      this.holiday.push(objHoliday)
    },
    async fetchDayOfSprint() {
      const { data } = await api.developmentInfo.dayOfSprint()
      this.dayOfSprintOption = data.data
    },
    async fetchHoliday(start, end) {
      const { data } = await api.developmentInfo.holidays(start, end)
      let dates = []
      dates = data.data
      dates.forEach(element => {
        if (element.date >= this.developmentPeriodStart && element.date <= this.developmentPeriodEnd) {
          this.holiday.push(element.date)
        }
      })
    },
    prevStep() {
      this.$emit('next', 3)
      localStorage.setItem('step', 3)
    },
    nextStep() {
      this.$emit('next', 5)
      localStorage.setItem('step', 5)
    },
    cancel() {
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      this.$router.push('/project-list')
    }
  }
}

</script>

<style lang="scss" scoped>
.button-project {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
