<template>
  <b-modal
    id="modal-term"
    :title="isEdit ? 'Edit Term' : 'Add New Term'"
    size="lg"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col>
          <validation-provider
            name="Term Title"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Term Title *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter term title"
                v-model="addTermForm.title"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Date"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Date *"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="addTermForm.invoiceDate"
                @input="invoiceDateChange"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group
            label="Reminder Date"
          >
            <b-form-datepicker
              v-model="addTermForm.reminderDate"
              :max="maxDate()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <validation-provider
            name="Percentage"
            rules="min_value:0|max_value:100"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Percentage"
              :invalid-feedback="errors[0]"
            >
              <b-input-group
                append="%"
              >
                <input
                  class="form-control"
                  placeholder="0"
                  v-model="addTermForm.percentage"
                  type="text"
                  @input="restrictInput($event)"
                  :class="{ 'is-invalid': errors.length }"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Amount"
            :rules="isEdit ? 'required|min_value:1' : `required|min_value:1|max_value:${totalRemaining}`"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Amount *"
              :invalid-feedback="errors[0]"
            >
              <b-input-group
                prepend="Rp"
                :class="{ 'is-invalid': errors.length }"
              >
                <money
                  class="form-control"
                  :class="{ 'is-invalid': errors.length }"
                  v-model="newAddTermFormAmount"
                  v-if="newAddTermFormAmount"
                  placeholder="0"
                  @input="restrictAmount"
                  :disabled="addTermForm.percentage != 0"
                />
                <money
                  class="form-control"
                  :class="{ 'is-invalid': errors.length }"
                  v-model="addTermForm.amount"
                  v-else
                  placeholder="0"
                  @input="restrictAmount"
                  :disabled="addTermForm.percentage != 0"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div class="d-flex mb-3 mt-3 pl-1">
        <h6 class="mr-5">
          Tax
        </h6>
        <b-form-checkbox-group
          v-model="addTermForm.taxes"
          :options="taxList"
          text-field="name"
          value-field="name"
          @input="calculateAmount"
        />
      </div>
      <b-row>
        <b-col>
          <b-form-group
            label="Remarks"
          >
            <b-form-textarea
              v-model="addTermForm.remarks"
              placeholder="Enter your remarks"
              rows="4"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Additional Note"
          >
            <vue-editor
              v-model="addTermForm.invoiceNote"
              :editor-toolbar="customToolbar"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      class="primary shadow"
      variant="warning"
      block
      @click="submit"
    >
      Submit
    </b-button>
  </b-modal>
</template>

<script>
import api from '@/api'
export default {
  props: {
    addTermForm: {
      type: Object,
      default: () => ({})
    },
    taxList: {
      type: Array,
      default: () => ([])
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    totalRemaining: {
      type: Number,
      default: () => 0
    }
  },
  data: () => ({
    taxId: null,
    newAddTermFormAmount: null,
    customToolbar: [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'formula'],
      [{ direction: 'rtl' }],
      ['clean']
    ]
  }),
  created() {
    this.fetchProjectAmount()
  },
  methods: {
    restrictAmount(e) {
      if (e < 0 || this.addTermForm.amount.toString().includes('-')) {
        const str = this.addTermForm.amount.toString().replace('-', '')
        this.addTermForm.amount = Number(str)
      }
    },
    restrictInput(event) {
      if (event.target.value && !isNaN(event.target.value)) {
        this.calculateAmount()
      } else {
        this.addTermForm.percentage = this.addTermForm.percentage.slice(0, -1)
      }
    },
    async fetchProjectAmount() {
      this.isUpdate = !!this.$route.query.id
      const id = this.$route.query.id ?? localStorage.getItem('projectId')
      const { data } = await api.paymentTerm.get(id)
      this.projectAmount = data.data.projectAmount
    },
    calculateAmount() {
      const amount = this.projectAmount
      const dpp = this.addTermForm.amount / 1.1
      const ppn = 10 / 100
      const pph = 2 / 100
      let newAmount = (this.addTermForm.percentage) * amount / 100
      if (this.addTermForm.taxes.includes('PPh 23') || this.addTermForm.taxes.includes('PPN')) {
        if (this.addTermForm.taxes.includes('PPN')) {
          newAmount += (dpp * ppn)
        }
        if (this.addTermForm.taxes.includes('PPh 23')) {
          newAmount -= (this.addTermForm.taxes.includes('PPN')
            ? newAmount * pph
            : dpp * pph)
        }
        this.newAddTermFormAmount = newAmount
      } else {
        this.newAddTermFormAmount = null
        this.addTermForm.amount = newAmount
      }
    },
    invoiceDateChange() {
      const date = new Date(this.addTermForm.invoiceDate)

      const year = date.getFullYear()
      const month = date.getDate() > 3 ? date.getMonth() + 1 : date.getMonth()
      const day = date.getDate() > 3
        ? date.getDate() - 3
        : this.daysInMonth(month, year) + date.getDate() - 3

      this.addTermForm.reminderDate = `${year}-${month}-${day}`
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate()
    },
    maxDate() {
      const date = new Date(this.addTermForm.invoiceDate)

      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate() - 1

      return `${year}-${month}-${day}`
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isBusy = false
        return false
      }
      this.addTermForm.amount = this.newAddTermFormAmount || this.addTermForm.amount
      this.$emit('set', this.addTermForm)
      this.newAddTermFormAmount = null
      this.$bvModal.hide('modal-term')
      this.$options.data()
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
</style>
