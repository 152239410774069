var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-project"},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h5',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" Payment Term ")]),_c('b-button',{staticClass:"next shadow-lg",attrs:{"variant":"warning","disabled":_vm.isBusy},on:{"click":function($event){return _vm.openModal(null, false)}}},[_vm._v(" Add Term ")])],1),_c('h6',{staticClass:"font-weight-bold"},[_vm._v(" Project Amount "+_vm._s(_vm.formatMoney(_vm.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]),_c('h6',{staticClass:"font-weight-bold"},[_vm._v(" Total Remaining "+_vm._s(_vm.formatMoney(_vm.totalRemaining, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]),_c('div',{staticClass:"d-flex align-items-end"}),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"Payment due date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment due date","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentDueDate),expression:"paymentDueDate"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0","type":"text"},domProps:{"value":(_vm.paymentDueDate)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.paymentDueDate=$event.target.value},function($event){return _vm.restrictInput($event)}]}})])]}}])})],1),_c('b-col',{staticClass:"d-flex align-items-end"},[_c('validation-provider',{attrs:{"name":"Payment Due Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"text","clearable":false,"options":_vm.dateTypeOptions},model:{value:(_vm.paymentDueUnit),callback:function ($$v) {_vm.paymentDueUnit=$$v},expression:"paymentDueUnit"}})],1)]}}])})],1)],1)],1),_c('b-alert',{attrs:{"show":_vm.totalAmount > _vm.projectAmount,"variant":"danger"}},[_vm._v(" The total amount in the table exceeds the project amount! ")]),_c('b-alert',{attrs:{"show":_vm.totalPercentage > 100,"variant":"danger"}},[_vm._v(" The total percentage in the table exceeds 100%! ")]),_c('b-alert',{attrs:{"show":_vm.isLow,"variant":"danger"}},[_vm._v(" The total percentage in the table is less than 100%! ")]),_c('b-table',{staticClass:"mt-2",attrs:{"striped":"","hover":"","items":_vm.dataTerm,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(invoiceDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoiceDate ? _vm.moment(item.invoiceDate).format('DD MMM YYYY') : '')+" ")]}},{key:"cell(percentage)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentage)+"% ")]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.amount, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(invoiceNote)",fn:function(ref){
var item = ref.item;
return [(item.invoiceNote)?_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.openNote(item.invoiceNote)}}},[_c('fa-icon',{attrs:{"icon":"clipboard-list"}})],1):_vm._e()]}},{key:"cell(reminderDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reminderDate? _vm.moment(item.reminderDate).format('DD MMM YYYY') : '')+" ")]}},{key:"cell(invoices)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoiceId)+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"data"},[_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.openModal(item, true)}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1),_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.removeTerm(index)}}},[_c('fa-icon',{attrs:{"icon":"trash-alt"}})],1)],1)]}}])}),_vm._m(0),_c('div',{staticClass:"button-project d-flex justify-content-end mt-3 mb-2"},[(!_vm.isUpdate)?_c('b-button',{staticClass:"cancel",attrs:{"type":"submit","variant":"secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel "),(_vm.isBusy)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Spinning","small":""}}):_vm._e()],1):_vm._e(),(!_vm.isUpdate)?_c('b-button',{staticClass:"reset ml-3",attrs:{"variant":"outline-warning"},on:{"click":_vm.prevStep}},[_vm._v(" Prev ")]):_vm._e(),(!_vm.isUpdate)?_c('b-button',{staticClass:"next ml-3",attrs:{"type":"submit","variant":"warning","disabled":_vm.isBusy},on:{"click":function($event){return _vm.savePaymentTerm()}}},[_vm._v(" Next "),(_vm.isBusy)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Spinning","small":""}}):_vm._e()],1):_vm._e(),(_vm.isUpdate)?_c('b-button',{staticClass:"next ml-3",attrs:{"variant":"warning","disabled":_vm.isBusy},on:{"click":function($event){return _vm.savePaymentTerm()}}},[_vm._v(" Update ")]):_vm._e()],1),_c('modal-term',{attrs:{"tax-list":_vm.taxList,"add-term-form":_vm.addTermForm,"isEdit":_vm.isEdit,"total-remaining":_vm.totalRemaining},on:{"close":function($event){this.$options.data().addTermForm},"set":_vm.addTerm}}),_c('modal-additional-note',{attrs:{"item":_vm.note}}),_c('modal-confirmation-delete',{on:{"click":_vm.deleteTerm}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Total persentase = 100% tidak boleh kurang atau lebih & total amount > project amount")])])}]

export { render, staticRenderFns }