<template>
  <b-modal
    ref="modal-confirmation-delete"
    id="modal-confirmation-delete"
    centered
    hide-footer
    hide-header
  >
    <div class="px-3 py-5 text-center">
      <img src="@/assets/close_up.png" alt="" width="120" height="120">
      <h4 class="text-bold mt-4">Remove Term</h4>
      <p class="text-muted mb-5">Are you sure want to delete this form ?</p>
      <div class="mt-3 d-flex justify-content-center">
      <b-button
      class="reset mr-3"
          variant="outline-warning"
      @click="onClickBack">
        No, Cancel
      </b-button>
      <b-button
      class="next"
          variant="warning"
      @click="remove">
        Delete
      </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>

export default {
  props: {
  },
  methods: {
    onClickBack() {
      this.$bvModal.hide('modal-confirmation-delete')
    },
    remove() {
      this.$bvModal.hide('modal-confirmation-delete')
      this.$emit('click')
    }
  }
}
</script>
