<template>
  <div>
    <v-card class="pt-3 px-3 mb-4">
      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ projectName }}</h5>
        <b-badge
          class="px-3 py-2 ml-3"
        >
          {{ projectStatus }}
        </b-badge>
      </div>
      <div class="text-muted mt-3">
        Created date : 20 Jan 2022 | Last update : 12 Feb 2022
      </div>
      <hr class="mx-3 mt-4 mb-2">
      <v-tabs
        v-model="tab"
        centered
      >
        <v-tab href="#tab-1">
          Project Detail
        </v-tab>

        <v-tab href="#tab-2">
          Proposal
        </v-tab>

        <v-tab href="#tab-3">
          Payment Term
        </v-tab>

        <v-tab href="#tab-4">
          Development Info
        </v-tab>

        <v-tab href="#tab-5">
          Development Team
        </v-tab>

        <v-tab href="#tab-6">
          File
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="i in 6"
        :key="i"
        :value="'tab-' + i"
      >
        <v-card class="p-4">
          <project-detail v-if="i === 1" />
          <proposal v-if="i === 2" />
          <payment-term v-if="i === 3" />
          <development-info v-if="i === 4" />
          <development-team v-if="i === 5" />
          <files v-if="i === 6" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import projectDetail from '../newProject/projectDetail/Index.vue'
import paymentTerm from '../newProject/paymentTerm/Index.vue'
import proposal from '../newProject/proposal/Index.vue'
import developmentInfo from '../newProject/developmentInfo/Index.vue'
import files from '../newProject/files/Index.vue'
import developmentTeam from '../newProject/developmentTeam/Index.vue'

export default {
  components: {
    projectDetail,
    paymentTerm,
    proposal,
    developmentInfo,
    files,
    developmentTeam
  },
  data: () => ({
    tab: null,
    projectName: localStorage.getItem('projectName'),
    projectStatus: localStorage.getItem('projectStatus')
  }),
  methods: {
    nextStep(item) {
      this.tab = 'tab-' + item
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  background: rgba(83, 130, 251, 0.2);
  color: #5382FB;
}

.v-card {
  border-radius: 12px;
}
</style>
