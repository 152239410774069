<template>
  <b-modal
    id="modal-file"
    title="Add File"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col>
          <validation-provider
            name="File Name"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="File Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter file name"
                v-model="addFileForm.documentName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="File Descriptions"
          >
            <b-form-textarea
              v-model="addFileForm.documentDesc"
              placeholder="Enter File Descriptions"
              rows="4"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <label class="mb-4 font-weight-bold">File Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
           <input type="radio" id="one" value="Upload File" v-model="addFileForm.fileSource"/>
           <label for="one">Upload File&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>

           <input type="radio" id="two" value="Link" v-model="addFileForm.fileSource"/>
           <label for="two">Link</label>
        </b-col>
      </b-row>
      <b-row v-if="addFileForm.fileSource === 'Link'">
        <b-col>
        <b-form-group
              label="Link URL"
            >
              <b-form-input
                placeholder="Enter link url"
                v-model="addFileForm.link"
              />
            </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="addFileForm.fileSource === 'Upload File'">
        <b-col>
        <b-form-group
              label="File Upload"
            >
            <div class="dropbox_e">
                <input
                  type="file"
                  accept=".pdf, .xlsx, docx"
                  name="addFileForm.file"
                  placeholder="select file"
                  @change="onFileChange"
                  class="input-file_e"
                />
                <div id="preview" class="mt-1">
                  <img
                    src="@/assets/fi_upload.png"
                  />
                </div>
                </div>
                <br/>
                <label>*Maximum file upload 10MB</label>
                 <br>
                <label>**Format file can be .pdf, .docx, .xlsx</label>
                <br/>
                 <div class="mt-2">
                 <b-row >
                 <b-col>
                 {{ addFileForm.file ? addFileForm.file.name : addFileForm.fileNameOri }}
                 </b-col>
                 <b-col align="right">
                  <img
                    src="@/assets/Light.png"
                    v-if="addFileForm.file || addFileForm.fileName"
                    v-on:click="cancelUpload"
                    class="icon"
                  />
                  </b-col>
                  </b-row>
                  </div>
                  <br/>
            </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      class="primary shadow"
      variant="warning"
      block
      @click="submit"
    >
      Done
    </b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    addFileForm: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    file: null,
    fileUrl: null,
    documentName: null,
    documentDesc: null,
    fileSource: 'Link',
    link: null,
    fileUploads: null,
    fileUpload: null,
    fileUploadPreview: null
  }),

  methods: {
    async getBase64FromUrl(url) {
      const data = await fetch(url)
      const blob = await data.blob()
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    cancelUpload() {
      this.addFileForm.fileName = null
      this.addFileForm.file = null
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.addFileForm.file = file
      this.file = file
      this.addFileForm.fileNameOri = file.name
      var size = parseFloat(this.addFileForm.file.size / (1024 * 1024)).toFixed(2)
      if (size > 10) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.addFileForm.file = null
      } else {
        var check = file.name.split('.').pop()
        const arrDocType = ['pdf', 'docx', 'xlsx']
        if (arrDocType.includes(check.toLowerCase())) {
          this.isProposalNo = false
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
        } else {
          this.addFileForm.file = null
          e = ''
          this.$bvToast.toast('Only pdf, docx, xlsx files are allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isBusy = false
        return false
      }
      if (this.addFileForm.fileSource === 'Upload File') {
        this.addFileForm.fileUploads = this.addFileForm.file
      }
      if (this.addFileForm.id) {
        this.addFileForm.isUpdated = true
      } else {
        this.addFileForm.isUpdated = false
      }
      this.$emit('set', this.addFileForm)
      this.$bvModal.hide('modal-file')
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
.dropbox_e {
  border: 2px solid #D3D3D3;
  text-align: right;
}
.input-file_e {
  margin-top: -12px;
  margin-left: -180px;
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
</style>
