var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-project"},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('h5',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" Project Detail ")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Project Name","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Project Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your project name","trim":""},on:{"change":_vm.generateKey},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Project Code"}},[_c('b-form-input',{attrs:{"value":_vm.projectCode,"disabled":"","trim":""}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"KEY","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"KEY *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"trim":""},model:{value:(_vm.projectKey),callback:function ($$v) {_vm.projectKey=$$v},expression:"projectKey"}})],1)]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"Type of Cooperation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Cooperation *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select Cooperation","clearable":false,"options":_vm.cooperationOption},model:{value:(_vm.cooperation),callback:function ($$v) {_vm.cooperation=$$v},expression:"cooperation"}})],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Required Resource"}},[_c('custom-select',{attrs:{"options":_vm.newOpt,"label":"developmentRoles","closeOnSelect":false,"multiple":"","placeholder":"Select Required Resource","clearable":true},on:{"option:deselected":_vm.deselect},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var developmentRoles = ref.developmentRoles;
var quantity = ref.quantity;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(developmentRoles))]),_c('div',[_c('b-button',{attrs:{"disabled":quantity < 2,"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.decrement(developmentRoles)}}},[_vm._v(" - ")]),_vm._v(" "+_vm._s(quantity)+" "),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.increment(developmentRoles)}}},[_vm._v(" + ")])],1)])]}},{key:"selected-option",fn:function(ref){
var developmentRoles = ref.developmentRoles;
var quantity = ref.quantity;
return [_vm._v(" "+_vm._s(quantity)+" "+_vm._s(developmentRoles)+" ")]}}]),model:{value:(_vm.requiredResource),callback:function ($$v) {_vm.requiredResource=$$v},expression:"requiredResource"}})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Technology"}},[_c('custom-select',{attrs:{"label":"name","clearable":false,"options":_vm.technologyList,"multiple":"","taggable":"","reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.technology),callback:function ($$v) {_vm.technology=$$v},expression:"technology"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Status"}},[_c('custom-select',{attrs:{"label":"name","clearable":false,"options":_vm.statusList,"reduce":function (ref) {
	var name = ref.name;

	return name;
},"disabled":_vm.isUpdate},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"Short Description","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Short Description","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your short description","rows":"4","max-rows":"6"},model:{value:(_vm.shortDescription),callback:function ($$v) {_vm.shortDescription=$$v},expression:"shortDescription"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Description","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter your description","rows":"4","max-rows":"6"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Init State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Init State","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"clearable":false,"placeholder":"Select Init State","options":_vm.initStateOption},model:{value:(_vm.initState),callback:function ($$v) {_vm.initState=$$v},expression:"initState"}})],1)]}}])})],1)],1),_c('div',{staticClass:"d-flex mt-4 w-100 mb-5"},[_c('div',{staticClass:"w-50 pr-3"},[_c('h5',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" Client ")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Client Name"}},[_c('custom-select',{attrs:{"label":"code","options":_vm.clientList,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"placeholder":"Select Client","append-to-body":""},on:{"input":_vm.registerPIC},scopedSlots:_vm._u([{key:"list-footer",fn:function(){return [_c('div',{staticClass:"text-center mx-5 my-2"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add-client",modifiers:{"modal-add-client":true}}],staticClass:"next",attrs:{"variant":"warning","size":"sm","block":""}},[_vm._v(" Add New Client ")])],1)]},proxy:true}]),model:{value:(_vm.clientId),callback:function ($$v) {_vm.clientId=$$v},expression:"clientId"}})],1)],1)],1)],1),(_vm.clientId)?_c('div',{staticClass:"w-50 pl-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h5',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" PIC Project ")]),_c('div',{staticClass:"d-flex"},[_vm._v(" Same with PIC Client? "),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":""},on:{"change":_vm.registerPIC},model:{value:(_vm.isRegisteredPIC),callback:function ($$v) {_vm.isRegisteredPIC=$$v},expression:"isRegisteredPIC"}})],1)]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"PIC Name","rules":_vm.clientId ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"PIC Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter pic name","trim":""},model:{value:(_vm.picName),callback:function ($$v) {_vm.picName=$$v},expression:"picName"}})],1)]}}],null,false,3723322790)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"No Handphone","rules":_vm.clientId ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"No Handphone *","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.picPhone),expression:"picPhone"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter no handphone","type":"text"},domProps:{"value":(_vm.picPhone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.picPhone=$event.target.value},function($event){return _vm.restrictInput($event)}]}})])]}}],null,false,652090958)})],1)],1),_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"Email PIC","rules":_vm.clientId ? 'required|email' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email PIC *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter pic email","type":"email","trim":""},model:{value:(_vm.picEmail),callback:function ($$v) {_vm.picEmail=$$v},expression:"picEmail"}})],1)]}}],null,false,3533198127)})],1)],1)],1):_vm._e()])],1),_c('div'),_c('div',{staticClass:"button-project mt-5 d-flex justify-content-end"},[(!_vm.isUpdate)?_c('b-button',{staticClass:"cancel",attrs:{"type":"submit","variant":"secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]):_vm._e(),(!_vm.isUpdate)?_c('b-button',{staticClass:"next ml-3",attrs:{"variant":"warning","disabled":_vm.isBusy},on:{"click":function($event){return _vm.saveProjectDetail()}}},[_vm._v(" Next ")]):_vm._e(),(_vm.isUpdate)?_c('b-button',{staticClass:"next ml-3",attrs:{"variant":"warning","disabled":_vm.isBusy},on:{"click":function($event){return _vm.saveProjectDetail()}}},[_vm._v(" Update ")]):_vm._e()],1),_c('modal-add-client',{on:{"submit":_vm.submitClient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }