<template>
    <b-modal
    id="modal-add-client"
    title="Add Client"
    hide-footer
    size="xl"
    @hidden="resetForm"
  >
   <validation-observer
      ref="form"
      slim
    >
    <b-row>
        <b-col>
          <validation-provider
            name="Company Code"
            rules="required|min:3|max:10"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Company Code *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Company Code"
                v-model="companyCode"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
                <b-col>
          <validation-provider
            name="Company Name"
            rules="required|min:3|max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Company Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Company Name"
                v-model="companyName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
     <b-row>
    <b-col cols="6">
          <validation-provider
            name="Company Address"
            rules="required|max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Company Address *"
              :invalid-feedback="errors[0]"
            >
             <b-form-textarea
              v-model="companyAddress"
              placeholder="Enter Company Address"
              rows="4"
              max-rows="6"
              :class="{ 'is-invalid': errors.length }"
            />
            </b-form-group>
          </validation-provider>
    </b-col>
    <b-col>
      <b-row>
        <b-col cols="6">
          <validation-provider
            name="Country"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Country *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="country"
                label="name"
                :clearable="false"
                placeholder="Select Country"
                :options="countryOption"
                @input="fetchProvince(country)"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            name="Province"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Province *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="province"
                label="name"
                :clearable="false"
                :options="provinceOption"
                placeholder="Select Province"
                @input="fetchCity(province)"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        </b-row>
      <b-row>
        <b-col cols="4">
          <validation-provider
            name="City"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="City *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="city"
                label="name"
                :clearable="false"
                placeholder="Select City"
                :options="cityOption"
                @input="fetchDistrict(city)"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <validation-provider
            name="District"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="District *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="district"
                :clearable="false"
                label="name"
                :options="districtOption"
                placeholder="Select District"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
            <b-form-group
              label="Zipcode "
            >
               <b-form-input
                placeholder="Enter Zipcode"
                v-model="zipCode"
                type="number"
              />
            </b-form-group>
        </b-col>
        </b-row>
    </b-col>
      </b-row>
      <h5>PIC Client</h5>
      <b-row>
        <b-col>
          <validation-provider
            name="PIC Name"
            rules="required|min:3|max:50"
            v-slot="{ errors }"
          >
            <b-form-group
              label="PIC Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter PIC Name"
                v-model="picName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
         <b-col>
      <b-row>
        <b-col cols="6">
          <validation-provider
            name="Email PIC"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Email PIC *"
              :invalid-feedback="errors[0]"
            >
             <b-form-input
                placeholder="Enter Email PIC"
                v-model="emailPIC"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            name="Phone Number"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Phone Number *"
              :invalid-feedback="errors[0]"
            >
              <input
                class="form-control"
                placeholder="Enter no handphone"
                v-model="picPhone"
                type="text"
                @input="restrictInput($event)"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        </b-row>
        </b-col>
      </b-row>
      <h5>Invoice Detail</h5>
      <b-row>
        <b-col>
          <validation-provider
            name="Invoice Name"
            rules="required|max:25|min:3"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Invoice Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Invoice Name"
                v-model="invoiceName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
          <b-col>
          <validation-provider
            name="Email Invoice"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Email Invoice *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Email Invoice"
                v-model="emailInvoice"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
   </validation-observer>
   <b-row>
    <b-col>
     <b-button
      class="primary shadow"
      variant="warning"
      block
      :disabled="isBusy"
      @click="submit"
    >
        Save
    </b-button>
   </b-col>
  </b-row>
  </b-modal>
  </template>

<script>
import moment from 'moment'
import api from '@/api'

export default {
  props: {
    projectId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isBusy: false,
    companyCode: null,
    companyName: null,
    companyAddress: null,
    country: null,
    countryOption: [],
    province: null,
    provinceOption: [],
    city: null,
    cityOption: [],
    district: null,
    districtOption: [],
    zipCode: null,
    picName: null,
    emailPIC: null,
    picPhone: null,
    invoiceName: null,
    emailInvoice: null,
    provinceList: [],
    cityList: []
  }),

  created() {
    this.fetchCountry()
  },

  methods: {
    restrictInput(event) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        this.picPhone = this.picPhone.slice(0, -1)
      }
    },
    reloadPage() {
      window.location.reload()
    },
    formatDate(date) {
      var value = date === '' ? 'YYYY-MM-DD' : moment(date).format('YYYY-MM-DD')
      return value
    },
    async fetchCountry() {
      const { data } = await api.address.country()
      this.countryOption = data.data
      this.provinceOption = []
      this.cityOption = []
      this.districtOption = []
    },
    async fetchProvince(country) {
      this.province = null
      this.city = null
      this.district = null
      const { data } = await api.address.provinceById(country.id)
      this.provinceOption = data.data
      this.cityOption = []
      this.districtOption = []
    },
    async fetchCity(province) {
      this.city = null
      this.district = null
      const { data } = await api.address.cityById(province.id)
      this.cityOption = data.data
      this.districtOption = []
    },
    async fetchDistrict(city) {
      this.district = null
      const { data } = await api.address.districtById(city.id)
      this.districtOption = data.data
    },
    async submit() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        const form = {
          code: this.companyCode,
          name: this.companyName,
          address: this.companyAddress,
          country: this.country.name ? this.country.name : this.country,
          state: this.province.name ? this.province.name : this.province,
          city: this.city.name ? this.city.name : this.city,
          district: this.district.name ? this.district.name : this.district,
          zipCode: this.zipCode,
          picName: this.picName,
          picPhone: this.picPhone,
          picEmail: this.emailPIC,
          invoiceName: this.invoiceName,
          invoiceEmail: this.emailInvoice
        }
        this.$emit('submit', form)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    resetForm() {
      this.companyCode = null
      this.companyName = null
      this.companyAddress = null
      this.country = null
      this.province = null
      this.city = null
      this.zipCode = null
      this.picName = null
      this.picPhone = null
      this.emailPIC = null
      this.invoiceName = null
      this.emailInvoice = null
      this.isBusy = false
    }
  }
}
</script>
