<template>
  <div class="card-project">
    <validation-observer
      ref="form"
      slim
    >
      <h5 class="mb-4 font-weight-bold">
        Proposal
      </h5>
     <div class="d-flex mt-4 w-100">
        <div class="w-50 pr-3">
          <b-row class="mb-2">
            <b-col>
              <validation-provider
                name="Proposal No"
                :rules='{ required: proposal_document !== null || amount > 0 || sentDate !== null }'
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Proposal No"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder=""
                    v-model="proposalNo"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                    @change="onFileChange"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">
              <validation-provider
                name="Amount"
                :rules='{ required: proposal_document !== null || proposalNo !== null || sentDate !== null, min_value: 0 }'
                v-slot="{ errors }"
              >
                <b-form-group
              label="Amount"
              :invalid-feedback="errors[0]"
            >
              <b-input-group
                prepend="Rp"
                :class="{ 'is-invalid': errors.length }"
              >
                 <money
                  class="form-control"
                  placeholder="0"
                  v-model="amount"
                  @input="restrictInput($event)"
                  @change="onFileChange"
                  :class="{ 'is-invalid': errors.length }"
                />
              </b-input-group>
            </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
          <validation-provider
            name="Send Date"
            :rules='{ required: amount > 0 || proposal_document !== null || proposalNo !== null }'
            v-slot="{ errors }"
          >
            <b-form-group
              label="Send Date"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="sentDate"
                :class="{ 'is-invalid': errors.length }"
                @change="onFileChange"
              />
            </b-form-group>
          </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-form-group
                label="Remarks"
              >
                <b-form-textarea
                  v-model="remark"
                  placeholder="Enter your remarks"
                  rows="4"
                  max-rows="6"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="w-50 pl-3">
          <b-row class="mb-2">
              <b-col>
              <div class="dropbox_e">
                <input
                  type="file"
                  accept=".doc, .pdf, .docx"
                  name="proposal_document"
                  @change="onProposalDocumentChange"
                  class="input-file_e"
                />
                <div id="preview" class="mt-2">
                  <img src="@/assets/icon-upload.png" />
                </div>
                <div class="upload-area">
                <b-button
                class="primary shadow next button-upload"
                  size="sm"
                >
                  {{ 'Upload Here' }}
                </b-button>
                <label class="text-upload-file">or Drag and drop files here</label>
                  </div>
                </div>
                 <span
              v-if="isProposalNo"
              class="px-3 mt-1 text-danger">The Upload File field is required</span>
                <br/>
                <label class="text-upload">*Maximum file upload 10MB</label>
                 <br>
                <label class="text-upload">**Format file can be .pdf</label>
                <br/>
                 <div class="mt-2 text-upload">
                 <b-row >
                 <b-col>
                 {{ fileNameOri ? fileNameOri : proposal_document ? proposal_document.name : ''  }}
                 </b-col>
                 <b-col align="right">
                   <img
                    src="@/assets/fi_download.png"
                    v-if="proposal_document !== null && fileName === null"
                    v-on:click="download(proposal_document)"
                  >
                  <img
                    src="@/assets/fi_download.png"
                    v-if="fileName !== null && proposal_document === null"
                    v-on:click="downloadExisting(fileName)"
                  >
                  <img
                    src="@/assets/Light.png"
                    v-if="proposal_document || fileNameOri"
                    v-on:click="cancelUpload"
                    class="icon"
                  />
                  </b-col>
                  </b-row>
                  </div>
              </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex mt-4 w-100">
        <div class="w-50 pr-3">
          <h5 class="mb-4 font-weight-bold">
            Contract (Optional)
          </h5>
          <b-row class="mb-2">
            <b-col>
              <validation-provider
                name="Garuda Contract No"
                :rules='{ required: gik_contract_document !== null }'
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Garuda Contract No"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter garuda contract no"
                    v-model="garudaContractNo"
                    trim
                    @change="onFileChange"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
                <div class="dropbox_e">
                <input
                  type="file"
                  accept=".doc, .pdf, .docx"
                  name="gik_contract_document"
                  @change="onGarudaContractDocumentChange"
                  class="input-file_e"
                />
                <div id="preview" class="mt-2">
                  <img src="@/assets/icon-upload.png" />
                </div>
                <div class="upload-area">
                <b-button
                class="primary shadow next button-upload"
                  size="sm"
                >
                  {{ 'Upload Here' }}
                </b-button>
                <label class="text-upload-file">or Drag and drop files here</label>
                  </div>
                </div>
                 <span
              v-if="isGikNo"
              class="px-3 mt-1 text-danger"
            >The Upload File field is required</span><br/>
             <b-row class="mb-2">
            <b-col>
                <label class="text-upload">*Maximum file upload 10MB</label>
                <br>
                <label class="text-upload">**Format file can be .pdf</label>
                </b-col>
                </b-row>

                <div class="mt-2 text-upload ml-auto">
                <b-row>
                <b-col>
                 {{ gikFileName ? gikFileNameOri : gik_contract_document ? gik_contract_document.name : '' }}
                 </b-col>
                 <b-col align="right">
                  <img
                    src="@/assets/fi_download.png"
                    v-if="gik_contract_document !== null && gikFileName === null"
                    v-on:click="download(gik_contract_document)"
                  >
                  <img
                    src="@/assets/fi_download.png"
                    v-if="gikFileName !== null && gik_contract_document === null"
                    v-on:click="downloadExisting(gikFileName)"
                  >
                  <img
                    src="@/assets/Light.png"
                    v-if="gik_contract_document || gikFileNameOri"
                    v-on:click="cancelUploadGaruda"
                  >
                  </b-col>
                  </b-row>
                  </div>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-form-group
                label="Other Info"
              >
                <b-form-textarea
                  v-model="otherInfo"
                  placeholder="Enter your info"
                  rows="4"
                  max-rows="6"
                />
              </b-form-group>
            </b-col>
          </b-row>
          </div>
          <div class="w-50 pl-3">
          <p class="mb-4 font-weight-bold">
            <br>
          </p>
          <b-row class="mb-2">
            <b-col>
              <validation-provider
                name="Client Contract No"
                :rules='{ required: client_contract_document !== null }'
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Client Contract No"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter client contract no"
                    v-model="clientContractNo"
                    trim
                    @change="onFileChange"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
                <div class="dropbox_e">
                <input
                  type="file"
                  accept=".doc, .pdf, .docx"
                  name="client_contract_document"
                  @change="onClientContractDocumentChange"
                  class="input-file_e"
                />
                <div id="preview" class="mt-2">
                  <img src="@/assets/icon-upload.png" />
                </div>
                <div class="upload-area">
                <b-button
                class="primary shadow next button-upload"
                  size="sm"
                >
                  {{ 'Upload Here' }}
                </b-button>
                <label class="text-upload-file">or Drag and drop files here</label>
                  </div>
                </div>
                 <span
              v-if="isClientNo"
              class="px-3 mt-1 text-danger">The Upload File field is required</span>
                <br/><label class="text-upload">*Maximum file upload 10MB</label>
                <br/>
                <label class="text-upload">**Format file can be .pdf</label>
                <br/>
                <div class="mt-2 text-upload">
                <b-row>
                <b-col>
                 {{ clientFileName ? clientFileNameOri : client_contract_document ? client_contract_document.name : '' }}
                 </b-col>
                 <b-col align="right">
                <img
                    src="@/assets/fi_download.png"
                    v-if="client_contract_document !== null && clientFileName === null"
                    v-on:click="download(client_contract_document)"
                  >
                   <img
                    src="@/assets/fi_download.png"
                    v-if="clientFileName !== null && client_contract_document === null"
                    v-on:click="downloadExisting(clientFileName)"
                  >
                  <img
                    src="@/assets/Light.png"
                    v-if="client_contract_document || clientFileNameOri"
                    v-on:click="cancelUploadClient"
                  >
                  </b-col>
                  </b-row>
                  </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </validation-observer>
    <div />
    <div class="button-project mt-5 d-flex justify-content-end">
      <b-button
        v-if="!isEdit"
        class="cancel"
        type="submit"
        variant="secondary"
        @click="cancel"
      >
        Cancel
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
          v-if="!isEdit"
          class="reset ml-3"
          variant="outline-warning"
          @click="prevStep"
        >
          Prev
        </b-button>
      <b-button
        v-if="!isEdit"
        class="next ml-3"
        type="submit"
        variant="warning"
        :disabled="isBusy"
        @click="saveProposal"
      >
        Next
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
        v-if="isEdit"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="saveProposal"
      >
        Update
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/api'

export default {
  created() {
    if (this.$route.query.id || localStorage.getItem('projectId')) this.fetchProposal()
  },
  methods: {
    restrictInput(e) {
      if (e < 0 || this.amount.toString().includes('-')) {
        const str = this.amount.toString().replace('-', '')
        this.amount = Number(str)
      }
    },
    async fetchProposal() {
      this.isEdit = !!this.$route.query.id
      const data = await api.proposal.get(this.$route.query.id)
      if (!data) return false
      if (data.status !== 404) {
        const propData = data.data
        this.clientContractNo = propData.clientContractNo
        this.garudaContractNo = propData.gikContractNo
        this.amount = propData.projectAmount ?? 0
        this.sentDate = propData.sentDate
        this.proposalNo = propData.documentNo
        this.otherInfo = propData.otherInfo
        this.remark = propData.remark
        this.fileNameOri = propData.fileNameOri
        this.clientFileNameOri = propData.clientFileNameOri
        this.gikFileNameOri = propData.gikFileNameOri
        this.fileName = propData.fileName
        this.gikFileName = propData.gikFileName
        this.clientFileName = propData.clientFileName
      }
    },
    onFileChange(e) {
      if ((this.proposalNo !== null || this.sentDate !== null || this.amount > 0) && !((this.fileName !== null && this.proposal_document === null) || (this.fileName === null && this.proposal_document !== null))) {
        this.isProposalNo = true
      }

      if (this.garudaContractNo !== null && !((this.gikFileName === null && this.gik_contract_document !== null) || (this.gikFileName !== null && this.gik_contract_document === null))) {
        this.isGikNo = true
      }

      if (this.clientContractNo !== null && !((this.clientFileName !== null && this.client_contract_document === null) || (this.clientFileName === null && this.client_contract_document !== null))) {
        this.isClientNo = true
      }
    },
    async saveProposal() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        var isProposalExist = this.proposalNo ? this.proposalNo && ((this.fileName !== null && this.proposal_document === null) || (this.fileName === null && this.proposal_document !== null)) : true
        var isClientExist = this.clientContractNo ? this.clientContractNo && ((this.clientFileName !== null && this.client_contract_document === null) || (this.clientFileName === null && this.client_contract_document !== null)) : true
        var isGikExist = this.garudaContractNo ? this.garudaContractNo && ((this.gikFileName === null && this.gik_contract_document !== null) || (this.gikFileName !== null && this.gik_contract_document === null)) : true
        if (!isProposalExist || !isGikExist || !isClientExist) {
          this.$bvToast.toast('The Upload File field is required', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
          this.isBusy = false
          return false
        }
        if (!valid) {
          this.isBusy = false
          return false
        }
        const formData = new FormData()
        formData.append('projectId', this.$route.query.id || localStorage.getItem('projectId'))
        formData.append('projectCode', this.$route.query.code || localStorage.getItem('projectCode'))
        formData.append('documentNo', this.proposalNo)
        formData.append('projectAmount', Number(this.amount))
        formData.append('sentDate', this.sentDate ?? '')
        formData.append('fileName', null)
        formData.append('fileNameOri', null)
        formData.append('gikContractNo', this.garudaContractNo)
        formData.append('gikFileName', null)
        formData.append('gikFileNameOri', null)
        formData.append('clientContractNo', this.clientContractNo)
        formData.append('clientFileName', null)
        formData.append('clientFileNameOri', null)
        formData.append('otherInfo', this.otherInfo)
        formData.append('remark', this.remark)
        formData.append('fileProposal', this.proposal_document ? this.proposal_document : null)
        formData.append('fileGikContract', this.gik_contract_document ? this.gik_contract_document : null)
        formData.append('fileClientContract', this.client_contract_document ? this.client_contract_document : null)
        const { data } = await api.proposal.save(formData)
        localStorage.setItem('amount', data.data.projectAmount)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success ${this.$route.query.id ? 'update' : 'save'} proposal`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.nextStep()
        }, 2000)
        this.isBusy = false
        this.fetchProposal()
        this.$emit('render')
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    nextStep() {
      this.$emit('next', 3)
      localStorage.setItem('step', 3)
    },
    prevStep() {
      this.$emit('next', 1)
      localStorage.setItem('step', 1)
    },
    cancelUpload() {
      this.fileName = null
      this.fileNameOri = null
      this.proposal_document = null
      this.isProposalNo = true
    },
    cancelUploadGaruda() {
      this.gikFileName = null
      this.gikFileNameOri = null
      this.gik_contract_document = null
      this.isGikNo = true
    },
    cancelUploadClient() {
      this.clientFileName = null
      this.clientFileNameOri = null
      this.client_contract_document = null
      this.isClientNo = true
    },
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY')
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url)
      const blob = await data.blob()
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    downloadExisting(item) {
      this.fetchDownload(item)
    },
    async fetchDownload(paramFileName) {
      await api.proposal.download(paramFileName).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        const fileName = paramFileName
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    },
    download(param) {
      const link = document.createElement('a')
      const url = URL.createObjectURL(param)
      link.href = url
      link.download = param.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
    onProposalDocumentChange(e) {
      this.fileNameOri = null
      const file = e.target.files[0]
      this.proposal_document = file
      this.file = file
      var size = parseFloat(this.proposal_document.size / (1024 * 1024)).toFixed(2)
      if (size > 10) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.proposal_document = null
      } else {
        var check = file.name.split('.').pop()
        const arrDocType = ['pdf', 'docx', 'xlsx']
        if (arrDocType.includes(check.toLowerCase())) {
          this.isProposalNo = false
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.fileName = null
        } else {
          this.proposal_document = null
          e = ''
          this.$bvToast.toast('Only pdf files are allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    onGarudaContractDocumentChange(e) {
      this.gikFileNameOri = ''
      const file = e.target.files[0]
      this.gik_contract_document = file
      this.file = file
      var size = parseFloat(this.gik_contract_document.size / (1024 * 1024)).toFixed(2)
      if (size > 10) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.gik_contract_document = null
      } else {
        var check = file.name.split('.').pop()
        const arrDocType = ['pdf', 'docx', 'xlsx']
        if (arrDocType.includes(check.toLowerCase())) {
          this.isGikNo = false
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.gikFileName = null
        } else {
          this.gik_contract_document = null
          e = ''
          this.$bvToast.toast('Only pdf, docx, xlsx files are allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    onClientContractDocumentChange(e) {
      const file = e.target.files[0]
      this.client_contract_document = file
      this.file = file
      var size = parseFloat(this.client_contract_document.size / (1024 * 1024)).toFixed(2)
      if (size > 10) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.client_contract_document = null
      } else {
        var check = file.name.split('.').pop()
        const arrDocType = ['pdf', 'docx', 'xlsx']
        if (arrDocType.includes(check.toLowerCase())) {
          this.isClientNo = false
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.clientFileName = null
        } else {
          this.client_contract_document = null
          e = ''
          this.$bvToast.toast('Only pdf, docx, xlsx files are allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    cancel() {
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      this.$router.push('/project-list')
    }
  },
  data: () => ({
    isBusy: false,
    amountDisplay: 2000.00,
    proposalUrl: null,
    fileNameOri: null,
    gikFileNameOri: null,
    clientFileNameOri: null,
    gikUrl: null,
    clientUrl: null,
    isProposalNo: false,
    isGikNo: false,
    isClientNo: false,
    clientContractNo: null,
    garudaContractNo: null,
    amount: 0,
    sentDate: null,
    proposalNo: null,
    otherInfo: null,
    remark: null,
    file: null,
    isProposal: false,
    overrideFile: null,
    isUpload: false,
    isOverride: false,
    proposal_document_preview: null,
    proposal_document: null,
    gik_contract_document: null,
    gik_contract_document_preview: null,
    client_contract_document: null,
    client_contract_document_preview: null,
    onUploadProgress: false,
    onUpload: false,
    fileName: null,
    gikFileName: null,
    clientFileName: null,
    fileUploads: [],
    upload: {
      progress: 0,
      variant: 'success'
    },
    isEdit: false
  })
}
</script>

<style lang="scss" scoped>
.input-file_e {
  margin-top: 5px;
  margin-left: 0px;
  opacity: 0;
  width: 500px;
  height: 150px;
  position: absolute;
  cursor: pointer;
}
.dropbox_e {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 158px;
  width: 539px;
  height: 156px;
  background: #F2F6FE;
  border: 3px dashed #CADCFF;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.button-upload {
  background: #0057FF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 14px;
  gap: 10px;

  width: 155px;
  height: 46px;

  /* Prepa Color */

  background: #0057FF;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.upload-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 340px;
  height: 96px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.text-upload {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.3);
  flex: none;
  order: 2;
  flex-grow: 0;
}
.text-upload-file {
  height: 17px;

  /* Web/Subtext 1 */

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.3);

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.row {
  margin-top: 0;
}
.col, .col-2, .col-6 {
  padding: 0 16px;
}
</style>
