var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-term","title":_vm.isEdit ? 'Edit Term' : 'Add New Term',"size":"lg","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","centered":""}},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Term Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Term Title *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter term title"},model:{value:(_vm.addTermForm.title),callback:function ($$v) {_vm.$set(_vm.addTermForm, "title", $$v)},expression:"addTermForm.title"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date *","invalid-feedback":errors[0]}},[_c('b-form-datepicker',{class:{ 'is-invalid': errors.length },on:{"input":_vm.invoiceDateChange},model:{value:(_vm.addTermForm.invoiceDate),callback:function ($$v) {_vm.$set(_vm.addTermForm, "invoiceDate", $$v)},expression:"addTermForm.invoiceDate"}})],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Reminder Date"}},[_c('b-form-datepicker',{attrs:{"max":_vm.maxDate()},model:{value:(_vm.addTermForm.reminderDate),callback:function ($$v) {_vm.$set(_vm.addTermForm, "reminderDate", $$v)},expression:"addTermForm.reminderDate"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Percentage","rules":"min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Percentage","invalid-feedback":errors[0]}},[_c('b-input-group',{attrs:{"append":"%"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addTermForm.percentage),expression:"addTermForm.percentage"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0","type":"text"},domProps:{"value":(_vm.addTermForm.percentage)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.addTermForm, "percentage", $event.target.value)},function($event){return _vm.restrictInput($event)}]}})])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Amount","rules":_vm.isEdit ? 'required|min_value:1' : ("required|min_value:1|max_value:" + _vm.totalRemaining)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount *","invalid-feedback":errors[0]}},[_c('b-input-group',{class:{ 'is-invalid': errors.length },attrs:{"prepend":"Rp"}},[(_vm.newAddTermFormAmount)?_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0","disabled":_vm.addTermForm.percentage != 0},on:{"input":_vm.restrictAmount},model:{value:(_vm.newAddTermFormAmount),callback:function ($$v) {_vm.newAddTermFormAmount=$$v},expression:"newAddTermFormAmount"}}):_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0","disabled":_vm.addTermForm.percentage != 0},on:{"input":_vm.restrictAmount},model:{value:(_vm.addTermForm.amount),callback:function ($$v) {_vm.$set(_vm.addTermForm, "amount", $$v)},expression:"addTermForm.amount"}})],1)],1)]}}])})],1)],1),_c('div',{staticClass:"d-flex mb-3 mt-3 pl-1"},[_c('h6',{staticClass:"mr-5"},[_vm._v(" Tax ")]),_c('b-form-checkbox-group',{attrs:{"options":_vm.taxList,"text-field":"name","value-field":"name"},on:{"input":_vm.calculateAmount},model:{value:(_vm.addTermForm.taxes),callback:function ($$v) {_vm.$set(_vm.addTermForm, "taxes", $$v)},expression:"addTermForm.taxes"}})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Remarks"}},[_c('b-form-textarea',{attrs:{"placeholder":"Enter your remarks","rows":"4","max-rows":"6"},model:{value:(_vm.addTermForm.remarks),callback:function ($$v) {_vm.$set(_vm.addTermForm, "remarks", $$v)},expression:"addTermForm.remarks"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Additional Note"}},[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.addTermForm.invoiceNote),callback:function ($$v) {_vm.$set(_vm.addTermForm, "invoiceNote", $$v)},expression:"addTermForm.invoiceNote"}})],1)],1)],1)],1),_c('b-button',{staticClass:"primary shadow",attrs:{"variant":"warning","block":""},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }