<template>
 <div class="card-project">
    <validation-observer
      ref="form"
      slim
    >
          <div class="d-flex justify-content-between">
        <h5 class="mb-4 font-weight-bold">
          Files
        </h5>
        <b-button
          class="next shadow-lg"
          variant="warning"
          :disabled="isBusy"
          @click="openModal()"
        >
          Add File
        </b-button>
      </div>
    </validation-observer>
    <br/>
    <b-row v-show="fileForm.length == 0">
      <b-col>
       <div align="center">
       <img src="@/assets/icon-no-files.png"/>
      </div>
      </b-col>
    </b-row>
    <b-table v-show="fileForm.length > 0"
      class="mt-2"
      striped
      hover
      :items="fileForm"
      :fields="fields"
    >
      <template #cell(link)="items">
      <a v-if="items.item.link !== null" href="" v-on:click.stop.prevent="openWindow(items.item.link)"> Link </a>
      <a v-if="items.item.link === null && items.item.fileName" href="" v-text="items.item.fileNameOri" v-on:click.stop.prevent="downloadExisting(items.item)" />
      <a v-if="items.item.link === null && !items.item.fileName" href="" v-text="items.item.fileUploads.name" v-on:click.stop.prevent="download(items.item)" />
      </template>

      <template #cell(no)="{ item, index }">
      {{ index + 1 }}
      </template>

      <template #cell(action)="{ item, index }">
        <div class="data">
          <b-link
            class="text-secondary mx-2"
            @click="openModal(item)"
          >
            <fa-icon icon="edit" />
          </b-link>
          <b-link
            class="text-secondary mx-2"
            @click="removeFile(index)"
          >
            <fa-icon icon="trash-alt"
                />
          </b-link>
        </div>
      </template>
    </b-table>
    <div class="button-project absolute-bottom d-flex justify-content-end mt-3 mb-2">
      <b-button
        v-if="!isUpdate"
        class="cancel"
        type="submit"
        variant="secondary"
        @click="cancel"
      >
        Cancel
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
          v-if="!isUpdate"
          class="reset ml-3"
          variant="outline-warning"
          @click="prevStep"
        >
          Prev
        </b-button>
      <b-button
        v-if="!isUpdate"
        class="next ml-3"
        type="submit"
        variant="warning"
        :disabled="isBusy"
        @click="saveFiles"
      >
        Finish
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
        v-if="isUpdate"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="saveFiles"
      >
        Update
      </b-button>
    </div>

    <modal-file
      :add-file-form="addFileForm"
      @close="this.$options.data().addFileForm"
      @set="addFile"
    />
    <modal-successful
    :message="message"
    :info="info"/>
</div>
</template>

<script>
import ModalFile from './ModalFile.vue'
import ModalSuccessful from './ModalSuccessful.vue'
import api from '@/api'

export default {
  components: {
    ModalFile,
    ModalSuccessful
  },

  computed: {
    dataFile() {
      return this.fileForm
    }
  },

  data: () => ({
    isBusy: false,
    isEdit: false,
    message: null,
    info: null,

    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'documentName', label: 'File Name', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'documentDesc', label: 'Description', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'link', label: 'File', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    projectId: localStorage.getItem('projectId'),
    projectCode: localStorage.getItem('projectCode'),
    addFileForm: {
      no: null,
      documentName: null,
      documentDesc: null,
      link: null,
      fileUpload: null,
      fileUploads: null,
      file: null,
      fileSource: [],
      isUpdated: false
    },
    fileForm: [],
    isUpdate: false
  }),

  created() {
    if (this.$route.query.id) this.fetchDetailFiles()
  },

  methods: {
    async saveFiles() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }
        const projectId = this.$route.query.id ? this.$route.query.id : localStorage.getItem('projectId')
        const projectCode = this.$route.query.code ? this.$route.query.code : localStorage.getItem('projectCode')
        const formData = new FormData()
        for (let i = 0; i < this.fileForm.length; i++) {
          const fileUploads = this.fileForm[i].fileUploads ? this.fileForm[i].fileUploads : null
          formData.append(`model[${i}].documentName`, this.fileForm[i].documentName)
          formData.append(`model[${i}].documentDesc`, this.fileForm[i].documentDesc)
          formData.append(`model[${i}].link`, this.fileForm[i].link)
          formData.append(`model[${i}].fileSource`, this.fileForm[i].fileSource)
          formData.append(`model[${i}].fileUploads`, fileUploads)
          if (this.fileForm[i].id) formData.append(`model[${i}].id`, this.fileForm[i].id)
          formData.append(`model[${i}].isUpdated`, this.fileForm[i].isUpdated || false)
        }
        if (this.isUpdate) {
          await api.files.edit(projectId, projectCode, formData)
          this.message = 'Files has been successfully updated'
          this.info = 'Updated'
        } else {
          await api.files.save(projectId, projectCode, formData)
          this.message = 'Data has been saved successfully'
          this.info = 'Added'
        }

        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save files', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.fetchDetailFiles()
        this.$bvModal.show('modal-successful')
        setTimeout(() => {
          this.fetchDetailFiles()
        }, 1500)
        this.isBusy = false

        if (!this.isUpdate) {
          localStorage.removeItem('projectId')
          localStorage.removeItem('projectCode')
          localStorage.removeItem('clientId')
          localStorage.removeItem('projectResource')
          localStorage.removeItem('projectName')
          localStorage.removeItem('projectStatus')
          localStorage.removeItem('amount')
          localStorage.removeItem('step')
          this.$router.push('/project-list')
        }
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    openWindow: function(link) {
      window.open(link)
    },
    downloadExisting(item) {
      this.fetchDownload(item.fileName)
    },
    async fetchDownload(paramFileName) {
      await api.files.download(paramFileName).then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        const fileName = paramFileName
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    },
    download(item) {
      const link = document.createElement('a')
      const url = URL.createObjectURL(item.file)
      link.href = url
      link.download = item.file.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
    openModal(item) {
      if (item) {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
      this.addFileForm = item || this.$options.data().addFileForm
      this.$bvModal.show('modal-file')
    },
    addFile(item) {
      if (!this.isEdit) this.fileForm.push(item)
    },
    removeFile(index) {
      this.fileForm.splice(index, 1)
    },
    async fetchDetailFiles() {
      this.isUpdate = this.$route.query.id
      const data = await api.files.get(this.$route.query.id, this.$route.query.code)

      this.fileForm = this.isUpdate ? data.status === 404 ? [] : data.data : this.fileForm
      this.fileForm.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.fileForm
    },
    prevStep() {
      this.$emit('next', 5)
      localStorage.setItem('step', 5)
    },
    cancel() {
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      this.$router.push('/project-list')
    }
  }
}
</script>

<style lang="scss" scoped>
.card-project {
  height: 650px;
}

.absolute-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}
.button-project {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
