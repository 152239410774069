<template>
  <b-modal
    id="modal-add-new-team"
    title="Add New Team"
    size="xl"
    hide-footer
    hide-header
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-between px-2 mb-3">
      <h5>Add New Team</h5>
      <b-link
        class="text-secondary"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row>
        <b-col>
          <b-form-group
            label="Search employee name"
          >
            <b-form-input
              v-model="filter.fullname"
              placeholder="Search employee"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Role employee"
          >
            <custom-select
              v-model="filter.developerRole"
              placeholder="Select Role"
              label="name"
              :options="developerRoles"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Avaibility"
          >
            <custom-select
              v-model="filter.avaibility"
              placeholder="Select Avaibility"
              :options="avaibilities"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Search level"
          >
            <custom-select
              v-model="filter.developerLevel"
              placeholder="Select Level"
              :options="developerLevels"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="mt-3 d-flex justify-content-end">
        <b-button
          class="reset mr-3"
          variant="outline-warning"
          @click="resetFilter"
        >
          Reset Filter
        </b-button>
        <b-button
          class="next"
          variant="warning"
          @click="fetchDeveloper"
        >
          Apply Filter
        </b-button>
      </div>
    </b-card>
    <b-card class="mt-3" body-class="p-2">
      <b-row>
        <b-col cols="8">
          <span class="font-weight-bold">Employee List</span>
          <div
            v-if="!developers.length"
            class="text-center"
          >
            <b-spinner
              v-if="isBusy"
              variant="primary"
            />
            <span v-else>No Data</span>
          </div>
          <ul
            v-else
            :style="gridStyle"
            class="card-list mt-3 p-0"
          >
            <b-card
              v-for="(item, p) in developers"
              :key="p"
              class="shadow-sm"
            >
              <div>
                <div class="mb-3 d-flex">
                  <b-avatar></b-avatar>
                  <div class="ml-3">
                    <div class="font-weight-bold mb-1">{{ item.fullname }}</div>
                    <b-badge
                      class="px-3 py-1 mr-2"
                      pill
                    >
                      {{ item.developerRole === 'Frontend Developer' ? 'FE' : 'BE'}} - {{ item.developerLevel }}
                    </b-badge>
                    <b-badge
                      class="px-3 py-1"
                      :class="{ 'success': item.projectCount === 0 }"
                      pill
                    >
                      {{ item.projectCount }} Project Involved
                    </b-badge>
                  </div>
                </div>
                <b-button
                  class="next"
                  variant="warning"
                  size="sm"
                  block
                  @click="openModal(item)"
                  :disabled="item.status === 'DELETE' || developerTeam.some(v => v.employeeId === item.id)"
                >
                  Add
                </b-button>
              </div>
              <b-collapse :id="`collapse` + p" class="mt-2">
                <div class="mb-2 font-weight-bold">Project Involved</div>
                <b-card
                  v-for="(k, j) in item.developers"
                  :key="j"
                  class="shadow-sm mb-3"
                >
                  <h6 class="font-weight-bold mb-2">{{ k.name ? k.name : '-' }}</h6>
                  <b-badge class="px-3 py-2 mr-2 mb-1">{{ k.status ? k.status : '-' }}</b-badge>
                  <div class="mb-1">{{moment(k.sprintStart).format('DD MMM YYYY')}} - {{moment(k.sprintEnd).format('DD MMM YYYY')}}</div>
                  <div>
                    <b-badge
                      v-for="(r, n) in k.developerRoles"
                      :key="n"
                      class="px-3 py-2 mr-2 mt-2"
                    >
                      {{ r.role === 'Frontend Developer' ? 'FE' : 'BE'}} - {{ r.level }}
                    </b-badge>
                  </div>
                </b-card>
              </b-collapse>
              <div
                class="mt-2 text-center pointer"
                v-b-toggle="'collapse' + p"
              >
                <fa-icon
                  icon="angle-down"
                  size="lg"
                />
              </div>
            </b-card>
          </ul>
          <div class="d-flex justify-content-end align-items-center">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              pills
              hide-goto-end-buttons
              @input="fetchDeveloper"
            />
          </div>
        </b-col>
        <b-col cols="4">
          <div class="mb-1">
            <span class="font-weight-bold">Required Resource</span>
          </div>
          <div class="mb-2">
            <b-badge
              v-for="(item, i) in resource"
              :key="i"
              class="px-3 py-2 m-2"
            >
              {{ `${item.qty} ${item.roleName} ${item.levelName}`}}
            </b-badge>
          </div>
          <div class="mb-3">
            <div class="mb-1 font-weight-bold">Selected Teams</div>
            <div class="mb-4">
              <b-badge
                v-for="(item, i) in selectedTeam"
                :key="i"
                class="px-3 py-2 m-2"
              >
                {{ `${item.qty} ${item.roleName} ${item.levelName}`}}
              </b-badge>
            </div>
            <b-row
              v-for="(item, i) in developerTeam"
              :key="i"
              class="mx-3 mb-4"
              align-v="center"
            >
              <b-col class="p-0" cols="2">
                <b-avatar></b-avatar>
              </b-col>
              <b-col class="p-0" cols="7">
                <div class="font-weight-bold mb-1">{{ item.fullname }}</div>
                <b-badge
                  v-for="(value, index) in item.roleLevel"
                  :key="index"
                  class="px-3 py-1 mr-2" pill
                >
                  {{ value.role.code ? value.role.code : value.role.name }} - {{ value.level.name }}
                </b-badge>
              </b-col>
              <b-col class="p-0" cols="3">
                <div class="d-flex">
                  <b-link class="text-secondary mx-2">
                    <fa-icon
                      class="fa-lg"
                      icon="edit"
                      @click="editEmployee(item, i)"
                    />
                  </b-link>
                  <b-link class="text-danger mx-2">
                    <fa-icon
                      class="fa-lg"
                      :icon="['far', 'times-circle']"
                      @click="removeEmployee(i)"
                    />
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="mt-4 mb-1">
      <b-col>
        <b-button
          class="cancel"
          variant="secondary"
          block
          @click="cancel"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button
          class="next"
          variant="warning"
          block
          @click="submit"
          :disabled="developerTeam.length === 0"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {
    developerTeam: {
      type: Array,
      default: () => ([])
    },
    developerInfo: {
      type: Object,
      default: () => ({})
    },
    developerEdit: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`
      }
    },
    stringifiedFilters() {
      let str = ''
      for (const key in this.filter) {
        if (key !== 'fullname' && this.filter[key]) str += `,${key}==${this.filter[key]}`
      }
      return str
    },
    selectedTeam() {
      const selectedTeam = []
      this.developerTeam.map(v => {
        if (selectedTeam.some(d => d.roleName === v.developerRole)) {
          const index = selectedTeam.findIndex(d => d.roleName === v.developerRole)
          selectedTeam[index].qty++
        } else {
          selectedTeam.push({
            qty: 1,
            roleName: v.roleLevel[0].role.name ? v.roleLevel[0].role.name : v.roleLevel[0].role.code,
            levelName: v.roleLevel[0].level.name
          })
        }
      })
      return selectedTeam
    }
  },

  data: () => ({
    isBusy: false,
    cardIndex: [],
    collapseVisible: true,
    numberOfColumns: 2,
    filter: {
      fullname: '',
      avaibility: null,
      developerRole: null,
      developerLevel: null
    },
    resource: null,
    developerRoles: [],
    avaibilities: ['Available', 'Not Available'],
    developerLevels: ['Junior', 'Middle', 'Senior'],
    developers: [],
    currentPage: 1,
    perPage: 6,
    totalRows: 0,
    totalPage: 0
  }),

  created() {
    this.fetchDeveloper()
    this.fetchDeveloperRoles()
    this.resource = JSON.parse(localStorage.getItem('projectResources'))
  },

  methods: {
    async fetchDeveloper() {
      const { data } = await api.developmentTeam.developers({
        filters: `fullname@=*${this.filter.fullname}${this.stringifiedFilters}`,
        sorts: null,
        page: this.currentPage,
        pageSize: 6
      })
      this.developers = data.data
      this.totalRows = data.totalData
      this.totalPage = data.totalPage
    },
    async fetchDeveloperRoles() {
      const { data } = await api.developmentTeam.roles()
      this.developerRoles = data.data
    },
    resetFilter() {
      this.filter = this.$options.data().filter
      this.fetchDeveloper()
    },
    openModal(item) {
      this.developerInfo.info = item
      this.$bvModal.show('modal-employee-settings')
    },
    editEmployee(item, index) {
      this.developerEdit.isEdit = true
      this.developerEdit.item = item
      this.developerEdit.index = index
      this.$bvModal.show('modal-employee-settings')
    },
    removeEmployee(index) {
      this.developerTeam.splice(index, 1)
    },
    async submit() {
      this.$emit('set')
      this.$bvModal.hide('modal-add-new-team')
    },
    cancel() {
      this.$bvModal.hide('modal-add-new-team')
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

.badge {
  background: #F6F4F4;
  color: #000;
}

.success {
  background: #4CAF50;
  color: #fff;
}
</style>
