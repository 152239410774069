<template>
  <div class="card-project" :class="{ 'empty': filterEmployees.length < 4 }">
    <validation-observer
      ref="form"
      slim
    >
      <h5 class="mb-4 font-weight-bold">
        Development Team
      </h5>
      <b-row>
        <b-col>
          <validation-provider
            name="Product Owner"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Product Owner"
              :invalid-feedback="errors[0]"
            >
            <b-form-input
              v-model="productOwner"
              placeholder="Enter Product Owner"
              :class="{ 'is-invalid': errors.length }"
              trim
            />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Scrum Master"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Scrum Master"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="scrumMaster"
                label="fullname"
                placeholder="Select Scrum Master"
                :options="scrumTeam"
                @search="fetchScrumTeam($event)"
                :reduce="({ id }) => id"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="mt-4">
      <div class="d-flex justify-content-between">
        <span>Development Team List</span>
        <b-button
          class="next"
          variant="warning"
          @click="openModal"
        >
          Add New
        </b-button>
      </div>
      <div
        v-if="!filterEmployees.length"
        class="text-center"
      >
        <img src="@/assets/icon-files.png" />
      </div>
      <ul
        v-else
        :style="gridStyle"
        class="card-list mt-4 m-0 p-0"
      >
        <b-card
          v-for="(item, p) in filterEmployees"
          :key="p"
          class="shadow-sm"
          body-class="p-3"
        >
          <div class="mb-3 d-flex">
            <b-avatar></b-avatar>
            <div class="ml-3">
              <div class="font-weight-bold mb-1">{{ item.fullname }}</div>
              <b-badge
                class="px-3 py-1 mr-2"
                pill
              >
                {{ item.developerRole }} - {{ item.developerLevel }}
              </b-badge>
              <b-badge
                v-if="item.isLeader"
                class="px-3 py-1 leader"
                pill
              >
                Leader
              </b-badge>
            </div>
          </div>
          <b-row>
            <b-col cols="8" class="text-secondary">
              <div>Role</div>
              <b-badge
                v-for="(value, index) in item.roleLevel"
                :key="index"
                class="px-3 py-1 mr-2"
                pill
              >
                {{ value.role.code ? value.role.code : value.role.name }} - {{ value.level.name }}
              </b-badge>
            </b-col>
            <b-col cols="4">
              <div class="text-secondary">Man A Day</div>
              <div>{{ item.manADay }}</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <div class="text-secondary">Periode</div>
              <div>{{moment(item.startDate).format('DD MMM YYYY')}} - {{moment(item.endDate).format('DD MMM YYYY')}}</div>
            </b-col>
            <b-col cols="4">
              <div class="text-secondary">Mandays</div>
              <div>{{ item.manDays }}</div>
            </b-col>
          </b-row>
          <div class="mt-3 d-flex justify-content-between">
            <b-form-checkbox v-model="item.isLeader">
              Assign as Leader
            </b-form-checkbox>
            <div class="d-flex">
              <b-link class="text-secondary mr-3">
                <fa-icon
                  icon="pen"
                  @click="editEmployee(item, p)"
                />
              </b-link>
              <b-link class="text-danger mr-3">
                <fa-icon
                  icon="trash"
                  @click="removeEmployee(p)"
                />
              </b-link>
            </div>
          </div>
        </b-card>
      </ul>
    </div>
    <div
      class="button-project d-flex justify-content-end mt-3 mb-2"
      :class="{ 'absolute-bottom': filterEmployees.length < 4 }"
    >
      <b-button
        v-if="!isUpdate"
        class="cancel"
        type="submit"
        variant="secondary"
        @click="cancel"
      >
        Cancel
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
          v-if="!isUpdate"
          class="reset ml-3"
          variant="outline-warning"
          @click="prevStep"
        >
          Prev
        </b-button>
      <b-button
        v-if="!isUpdate"
        class="next ml-3"
        type="submit"
        variant="warning"
        :disabled="isBusy"
        @click="saveDevelopmentTeam()"
      >
        Next
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
      <b-button
        v-if="isUpdate"
        class="next ml-3"
        variant="warning"
        :disabled="isBusy"
        @click="saveDevelopmentTeam()"
      >
        Update
      </b-button>
    </div>
    <modal-add-new-team
      :developerTeam="developerTeam"
      :developerInfo="developerInfo"
      :developerEdit="developerEdit"
      @set="setEmployees"
      @cancel="cancelAddNewTeam"
    />
    <remove-team-member />
    <employee-settings
      :developerInfo="developerInfo.info"
      :developerEdit="developerEdit"
      @submit="submitEmployee"
    />
  </div>
</template>

<script>
import api from '@/api'
import ModalAddNewTeam from './ModalAddNewTeam.vue'
import RemoveTeamMember from '@/components/RemoveTeamMember.vue'
import EmployeeSettings from '@/components/EmployeeSettings.vue'

export default {
  components: {
    ModalAddNewTeam,
    RemoveTeamMember,
    EmployeeSettings
  },

  data: () => ({
    isBusy: false,
    productOwner: null,
    scrumMaster: null,
    scrumTeam: [],
    employees: [],
    numberOfColumns: 3,
    leader: null,
    developerTeam: [],
    developerEdit: {
      item: null,
      isEdit: false,
      index: null
    },
    developerInfo: {
      info: null
    },
    isUpdate: null
  }),

  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`
      }
    },
    filterEmployees() {
      return this.employees.filter(v => v.status !== 'DELETE')
    }
  },

  created() {
    this.fetchScrumTeam('')
    if (this.$route.query.id) this.fetchGetDevelopmentTeam()
  },

  methods: {
    async fetchGetDevelopmentTeam() {
      this.isUpdate = !!this.$route.query.id
      const id = this.$route.query.id ?? localStorage.getItem('projectId')
      const { data } = await api.developmentTeam.get(id)

      if (data.status !== 404) {
        data.data.projectDevelopmentTeams.map(v => {
          v.roleLevel = []
          v.roleLevel.push({
            label: 0,
            role: {
              code: v.developmentTeamRoles[0].roleName
            },
            level: {
              name: v.developmentTeamRoles[0].levelName
            }
          })

          v.status = null

          v.developmentTeamRoleRequests = v.developmentTeamRoles

          delete v.developmentTeamRoles
        })
        this.productOwner = data.data.poDeveloper
        this.scrumMaster = data.data.smDeveloperId
        data.data.projectDevelopmentTeams.map(v => {
          this.employees.push(v)
          this.developerTeam.push(v)
        })
      }
    },
    submitEmployee(form) {
      const index = this.developerEdit.index

      if (this.developerEdit.isEdit) {
        if (this.developerEdit.isEmployee) {
          this.employees.splice(index, 1, form)
        }
        this.developerTeam.splice(index, 1, form)

        this.developerEdit = this.$options.data().developerEdit
      } else {
        this.developerTeam.push(form)
      }
    },
    async fetchScrumTeam(key) {
      if (key.length > 3) {
        const { data } = await api.developmentTeam.scrumTeams({
          Filters: `fullname@=*${key}`,
          Page: 1,
          PageSize: 10
        })
        this.scrumTeam = data
      } else {
        const { data } = await api.developmentTeam.scrumTeams({
          Page: 1,
          PageSize: 10
        })
        this.scrumTeam = data
      }
    },
    openModal() {
      this.$bvModal.show('modal-add-new-team')
    },
    setEmployees() {
      const filteredEmployees = this.developerTeam.filter(v => !this.employees.some(i => v.employeeId === i.employeeId))
      filteredEmployees.forEach(v => this.employees.push(v))
    },
    cancelAddNewTeam() {
      this.developerTeam = this.$options.data().developerTeam
    },
    editEmployee(item, index) {
      this.developerEdit.isEdit = true
      this.developerEdit.item = item
      this.developerEdit.index = index
      this.developerEdit.isEmployee = true
      this.$bvModal.show('modal-employee-settings')
    },
    removeEmployee(index) {
      this.employees[index].status = 'DELETE'
      this.developmentTeam = []
      this.filteredEmployees.map(v => this.developerTeam.push(v))
    },
    prevStep() {
      this.$emit('next', 4)
      localStorage.setItem('step', 4)
    },
    nextStep() {
      this.$emit('next', 6)
      localStorage.setItem('step', 6)
    },
    async saveDevelopmentTeam() {
      try {
        this.isBusy = true

        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        if (!this.employees.length) {
          this.isBusy = false
          return false
        }

        const form = {
          projectId: (this.$route.query.id) || localStorage.getItem('projectId'),
          scrumMasterId: this.scrumMaster,
          projectOwner: this.productOwner,
          developmentTeams: this.employees
        }

        form.developmentTeams.map(v => {
          delete v.developerLevel
          delete v.developerRole
          delete v.projectCount
          delete v.roleLevel

          v.developmentTeamRoleRequests.map(i => {
            if (i.levelName && i.roleName) {
              delete i.levelName
              delete i.roleName
            }
          })
        })

        await api.developmentTeam.save(form)

        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success ${this.isUpdate ? 'update' : 'save'} development team`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })

        if (!this.isUpdate) {
          setTimeout(() => {
            this.nextStep()
          }, 2000)
        }
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    cancel() {
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      this.$router.push('/project-list')
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  height: 85vh;
}

.absolute-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

.badge {
  background: #F6F4F4;
  color: #A4A4A4;

  &.leader {
    background: #FFF3DF;
    color: #F9BF59;
  }
}
</style>
