var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-employee-settings","title":"Employee settings","size":"lg","hide-footer":"","hide-header":"","hide-header-close":"","no-close-on-esc":"","no-close-on-backdrop":"","centered":""},on:{"hidden":_vm.resetModal}},[_c('div',{staticClass:"d-flex justify-content-between px-2 mb-3"},[_c('h5',[_vm._v(_vm._s(_vm.developerEdit.isEdit ? 'Edit ': '')+"Employee settings")]),_c('b-link',{staticClass:"text-secondary",on:{"click":_vm.cancel}},[_c('fa-icon',{attrs:{"icon":"times","size":"lg"}})],1)],1),_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Role","rules":_vm.developerEdit.isEdit ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Role *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select Role","clearable":false,"label":"name","options":_vm.roles},on:{"search":function($event){return _vm.fetchRole($event)}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Level","rules":_vm.developerEdit.isEdit ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Level","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Select Level","label":"name","options":_vm.levels},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}})],1)]}}])})],1)],1),_c('b-button',{staticClass:"next mb-3",attrs:{"variant":"warning"},on:{"click":_vm.addRoleLevel}},[_vm._v(" Add ")]),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Role-Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Add Role and Level first","multiple":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var role = ref.role;
var level = ref.level;
return [_vm._v(" "+_vm._s(role.name ? role.name : role.code)+" - "+_vm._s(level.name)+" ")]}}],null,true),model:{value:(_vm.form.roleLevel),callback:function ($$v) {_vm.$set(_vm.form, "roleLevel", $$v)},expression:"form.roleLevel"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Man A Day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Man A Day *","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.manADay),expression:"form.manADay"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0","type":"text"},domProps:{"value":(_vm.form.manADay)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "manADay", $event.target.value)},function($event){return _vm.restrictInput($event)}]}})])]}}])})],1)],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Start Period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Period","invalid-feedback":errors[0]}},[_c('b-form-datepicker',{class:{ 'is-invalid': errors.length },on:{"input":function($event){_vm.form.endDate = null}},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"2","align":"center"}},[_vm._v(" to ")]),_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"End Period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Period","invalid-feedback":errors[0]}},[_c('b-form-datepicker',{class:{ 'is-invalid': errors.length },attrs:{"disabled":!_vm.form.startDate,"min":_vm.form.startDate},on:{"input":_vm.calculateTotalMandays},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Total Mandays"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.form.manDays),callback:function ($$v) {_vm.$set(_vm.form, "manDays", $$v)},expression:"form.manDays"}})],1)],1)],1)],1),_c('b-button',{staticClass:"next",attrs:{"variant":"warning","block":""},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }