var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-client","title":"Add Client","hide-footer":"","size":"xl"},on:{"hidden":_vm.resetForm}},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Company Code","rules":"required|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Code *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Company Code"},model:{value:(_vm.companyCode),callback:function ($$v) {_vm.companyCode=$$v},expression:"companyCode"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Company Name"},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Address *","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Company Address","rows":"4","max-rows":"6"},model:{value:(_vm.companyAddress),callback:function ($$v) {_vm.companyAddress=$$v},expression:"companyAddress"}})],1)]}}])})],1),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"placeholder":"Select Country","options":_vm.countryOption},on:{"input":function($event){return _vm.fetchProvince(_vm.country)}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Province *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"options":_vm.provinceOption,"placeholder":"Select Province"},on:{"input":function($event){return _vm.fetchCity(_vm.province)}},model:{value:(_vm.province),callback:function ($$v) {_vm.province=$$v},expression:"province"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"City *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"placeholder":"Select City","options":_vm.cityOption},on:{"input":function($event){return _vm.fetchDistrict(_vm.city)}},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"District *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"clearable":false,"label":"name","options":_vm.districtOption,"placeholder":"Select District"},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Zipcode "}},[_c('b-form-input',{attrs:{"placeholder":"Enter Zipcode","type":"number"},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})],1)],1)],1)],1)],1),_c('h5',[_vm._v("PIC Client")]),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"PIC Name","rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"PIC Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter PIC Name"},model:{value:(_vm.picName),callback:function ($$v) {_vm.picName=$$v},expression:"picName"}})],1)]}}])})],1),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email PIC","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email PIC *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Email PIC"},model:{value:(_vm.emailPIC),callback:function ($$v) {_vm.emailPIC=$$v},expression:"emailPIC"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone Number *","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.picPhone),expression:"picPhone"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter no handphone","type":"text"},domProps:{"value":(_vm.picPhone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.picPhone=$event.target.value},function($event){return _vm.restrictInput($event)}]}})])]}}])})],1)],1)],1)],1),_c('h5',[_vm._v("Invoice Detail")]),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Invoice Name","rules":"required|max:25|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Invoice Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Invoice Name"},model:{value:(_vm.invoiceName),callback:function ($$v) {_vm.invoiceName=$$v},expression:"invoiceName"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Email Invoice","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email Invoice *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Email Invoice"},model:{value:(_vm.emailInvoice),callback:function ($$v) {_vm.emailInvoice=$$v},expression:"emailInvoice"}})],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"primary shadow",attrs:{"variant":"warning","block":"","disabled":_vm.isBusy},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }