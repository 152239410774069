<template>
  <b-modal
    id="modal-holiday"
    title="Add Holiday"
    hide-footer
  >
   <validation-observer
      ref="form"
      slim
    >

    <b-row>
        <b-col>
          <validation-provider
            name="Descriptions"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Descriptions *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter descriptions"
                v-model="devInfoForm.descriptions"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
     <b-row>
        <b-col>
          <validation-provider
            name="Date"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Date *"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="devInfoForm.date"
                :value="formatDate(devInfoForm.date)"
                :min="devInfoForm.startDate"
                :max="devInfoForm.endDate"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
   </validation-observer>
   <b-row>
    <b-col>
     <b-button
      class="primary shadow"
      variant="warning"
      block
    @click="submit"
    >
        Submit
    </b-button>
   </b-col>
  </b-row>
  </b-modal>
  </template>

<script>
import moment from 'moment'

export default {
  props: {
    devInfoForm: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isEnable: false,
    descriptions: null,
    date: null
  }),

  methods: {
    formatDate(date) {
      var value = date === '' ? 'YYYY-MM-DD' : moment(date).format('YYYY-MM-DD')
      return value
    },
    submit() {
      if (this.devInfoForm.descriptions !== null || this.devInfoForm.date !== null) this.$emit('set', this.devInfoForm)
      this.$bvModal.hide('modal-holiday')
      this.devInfoForm.descriptions = ''
      this.devInfoForm.date = ''
    }
  }
}
</script>
