<template>
  <b-modal
    ref="modal-successful"
    id="modal-successful"
    centered
    hide-footer
    hide-header
  >
    <div class="px-3 py-5 text-center">
      <img src="@/assets/files-upload-success.png" alt="" width="120" height="120">
      <h4 class="text-bold mt-4">Project {{ info }}</h4>
      <p class="text-muted mb-5">{{ message }}</p>
      <b-button
      class="primary shadow"
      variant="warning"
      block
      @click="onClickBack">
        Okay
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    message: {
      types: String,
      default: () => ''
    },
    info: {
      types: String,
      default: () => ''
    }
  },
  methods: {
    onClickBack() {
      this.$bvModal.hide('modal-successful')
    }
  }
}
</script>
